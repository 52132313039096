const leaflets = {
    "trony": {
        "config": {
            "primary-color": "#283263",
            "secondary-color": "#d4293c",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(212, 41, 60, 0.5)",
            "clientIcon": require("assets/images/clientLogo_trony.png"),
            "serverPath": "trony.volantinointerattivo.net/",
            "pdfPath": "/media/interactive_flyer_87/volantino_trony.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino Trony!",
            "shareFlyerURL": "https://trony.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Trony!",
            "ogTitleMeta": "Volantino Interattivo Trony",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://trony.volantinointerattivo.net/media/interactive_flyer_87/images/fine.png",
            "filterBrand": 1,
            "filterUnderprice": 1,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": true,
            "ga_tracking_id": "UA-152069090-4",
            "release_id": "1",
        },
        "leaflet": {
            "name": "Trony Falco",
            "code": "",
            "id_campaign": "",
            "index": {
                "image_file": "/media/interactive_flyer_87/pages/indice_trony.png",
                "links": [
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 14.359654000617853,
                            "left": 17.565431968551348,
                            "width": 64.47002907110885,
                            "height": 4.1744648925371814
                        }
                    },
                    {
                        "page": 4,
                        "blueprint": {
                            "top": 20.586760227724078,
                            "left": 17.565431968551348,
                            "width": 64.47002907110885,
                            "height": 4.1744648925371814
                        }
                    },
                    {
                        "page": 6,
                        "blueprint": {
                            "top": 26.960386601350457,
                            "left": 17.65662252164952,
                            "width": 64.47002907110885,
                            "height": 4.1744648925371814
                        }
                    },
                    {
                        "page": 8,
                        "blueprint": {
                            "top": 33.26075290171676,
                            "left": 17.47424141545318,
                            "width": 64.47002907110885,
                            "height": 4.1744648925371814
                        }
                    },
                    {
                        "page": 10,
                        "blueprint": {
                            "top": 39.85415949512335,
                            "left": 17.47424141545318,
                            "width": 64.47002907110885,
                            "height": 4.1744648925371814
                        }
                    },
                    {
                        "page": 12,
                        "blueprint": {
                            "top": 46.22778586874973,
                            "left": 17.47424141545318,
                            "width": 64.47002907110885,
                            "height": 4.1744648925371814
                        }
                    },
                    {
                        "page": 14,
                        "blueprint": {
                            "top": 52.6014122423761,
                            "left": 17.47424141545318,
                            "width": 64.47002907110885,
                            "height": 4.1744648925371814
                        }
                    },
                    {
                        "page": 16,
                        "blueprint": {
                            "top": 59.26807890904278,
                            "left": 17.565431968551348,
                            "width": 64.47002907110885,
                            "height": 4.1744648925371814
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Grandi Elettrodomestici",
                        "subcategories": [
                        "Asciugatrici",
                        "Congelatori",
                        "Forni",
                        "Frigoriferi",
                        "Lavatrici",
                        "Lavasciuga",
                        "Piani Cottura",
    
                    ]
                },
                {
                    "category_name": "Piccoli Elettrodomestici",
                        "subcategories": [

                        "Cura Persona",
                        "Forni Elettrici",
                        "Macchine da Caffè",
                        "Preparazione Cibo",
                        "Pulizia Casa",
                        "Stiro e Cucito",
    
                    ]
                },
                {
                    "category_name": "Tv",
                        "subcategories": [
                        "Bolva",
                        "LG",
                        "Panasonic",
                        "Samsung",
                        "Sony",
                        "Accessori"
                        
                    ]
                },
                {
                    "category_name": "Audio",
                        "subcategories": [
                        "Casse Bluetooth",
                        "Cuffie",
                        "Radio FM",
                        "Smart Audio"
                        
                    ]
                },
                {
                    "category_name": "Fotografia e Videocamere",
                        "subcategories": [
                        "ActionCam",
                        "Canon",
                        "Nikon",
                        "Polaroid",
                         
]
                },
                {
                    "category_name": "Games",
                        "subcategories": [
                        "Bici",    
                        "Console",
                        "Hoverboard",
                        "Accessori",
                        
]
                },
                
                {
                    "category_name": "Informatica",
                        "subcategories": [
                        "Internet",
                        "Notebook",
                        "Pc Desktop",
                        "Tablet",
                        "Sorveglianza",
                        "Software",
                        "Stampanti",
                        "Accessori"
                        ]
                },
                {
                    "category_name": "Telefonia",
                    "subcategories": [
                        "Apple",
                        "Brondi",
                        "Doro",
                        "Huawei",
                        "Neffos",
                        "Nokia",
                        "Samsung",
                        "Smartwatch",
                        "Xiaomi",
                    ]
                },
            

        ],
        "pages": [
            {
                "number": 1,
                "image_file": "/media/interactive_flyer_87/pages/page_1_Ab48H8z.jpg",
                "products": []
            },
            {
                "number": 2,
                "image_file": "/media/interactive_flyer_87/pages/page_2_cruSOsa.jpg",
                "products": [
                    {
                        "item_id": "611221",
                        "field1": "HOTPOINT ARISTON",
                        "field2": "LAVATRICE FRONTALE",
                        "field3": "RPD 1046 DD IT",
                        "field4": "",
                        "description": "HOTPOINT ARISTON LAVATRICE FRONTALE RPD 1046 DD IT",
                        "grammage": null,
                        "price": "399.00",
                        "price_label": "€ 399",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Lavatrici",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 33.831985294117636,
                            "height": 30.94200608383044
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_qJ829iZ_ZvHt2oV.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.trony.it/online/grandi-elettrodomestici/elettrodomestici/lavatrici/hotpoint-ariston-rpd-1046-dd-it_sku-2190012308"
                            }
                        ],
                        "related": ["700576", "619285", "641192", "605778",] ,
                    },
                    {
                        "item_id": "672255",
                        "field1": "ELECTROLUX ASCIUGATRICE",
                        "field2": "A POMPA DI CALORE",
                        "field3": "EDH4084GOW",
                        "field4": "",
                        "description": "ELECTROLUX ASCIUGATRICE A POMPA DI CALORE EDH4084GOW",
                        "grammage": 0,
                        "price": "499.00",
                        "price_label": "€ 499,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Asciugatrici",
                        "blueprint": {
                            "top": 0.0,
                            "left": 33.4842524509804,
                            "width": 33.831985294117636,
                            "height": 30.94200608383044
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_SzSWFgi_thl4iVn.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "754365",
                        "field1": "BEKO LAVASCIUGA",
                        "field2": "CARICA FRONTALE",
                        "field3": "HTV8733XW",
                        "field4": "",
                        "description": "BEKO LAVASCIUGA CARICA FRONTALE HTV8733XW",
                        "grammage": 0,
                        "price": "349.00",
                        "price_label": "€ 349,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Lavasciuga",
                        "blueprint": {
                            "top": 0.0,
                            "left": 66.16801470588234,
                            "width": 33.831985294117636,
                            "height": 30.94200608383044
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_vyM7aEA_6aAt6bo.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.trony.it/online/grandi-elettrodomestici/elettrodomestici/lavasciuga/beko-htv8733xw_sku-2160000695"
                            }
                        ]
                    },
                    {
                        "item_id": "700576",
                        "field1": "LG LAVATRICE",
                        "field2": "FRONTALE",
                        "field3": "F4J6VY1W",
                        "field4": "",
                        "description": "LG LAVATRICE FRONTALE F4J6VY1W",
                        "grammage": null,
                        "price": "349.00",
                        "price_label": "€ 349,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Lavatrici",
                        "blueprint": {
                            "top": 30.882352941176467,
                            "left": 0.0,
                            "width": 33.831985294117636,
                            "height": 30.4273002014775
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_F3AZSUl_kSpJiWQ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": ["611221", "619285", "641192", "605778",] ,
                    },
                    {
                        "item_id": "619285",
                        "field1": "BEKO LAVATRICE",
                        "field2": "FRONTALE",
                        "field3": "WTX91232WI",
                        "field4": "",
                        "description": "BEKO LAVATRICE FRONTALE WTX91232WI",
                        "grammage": null,
                        "price": "299.00",
                        "price_label": "€ 299",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Lavatrici",
                        "blueprint": {
                            "top": 30.80882352941176,
                            "left": 32.845588235294116,
                            "width": 33.831985294117636,
                            "height": 30.4273002014775
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_je3PTOx_AVfNtYl.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": ["611221", "700576", "641192", "605778",] ,
                    },
                    {
                        "item_id": "641192",
                        "field1": "AEG LAVATRICE",
                        "field2": "FRONTALE",
                        "field3": "L7FEE842",
                        "field4": "",
                        "description": "AEG LAVATRICE FRONTALE L7FEE842",
                        "grammage": 0,
                        "price": "579.00",
                        "price_label": "€ 579,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Lavatrici",
                        "blueprint": {
                            "top": 30.51470588235294,
                            "left": 66.16801470588234,
                            "width": 33.831985294117636,
                            "height": 30.20671196618338
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_AmTMuqE_JTCan8y.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "Lavatrice AEG",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_87/images/l7fee.jpeg"
                                    ],
                                    "specifiche": "Tipo di carica Frontale, Dimensioni Normali, Capacità max di carico in Kg 8, Velocità max di centrifuga (giri/min) 1400, Classe energetica A+++",
                                    
                                }
                            },
                        ],
                        "related": ["611221", "700576", "619285", "605778",] ,
                    },
                    {
                        "item_id": "779104",
                        "field1": "BOSCH ASCIUGATRICE",
                        "field2": "A POMPA DI CALORE",
                        "field3": "WTW855R8IT",
                        "field4": "",
                        "description": "BOSCH ASCIUGATRICE A POMPA DI CALORE WTW855R8IT",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Asciugatrici",
                        "blueprint": {
                            "top": 60.588235294117645,
                            "left": 0.0,
                            "width": 34.379411764705885,
                            "height": 30.20671196618338
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_V3vwBdb_DR9A42N.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "694535",
                        "field1": "INDESIT ASCIUGATRICE",
                        "field2": "A POMPA DI CALORE",
                        "field3": "YTM1081REU",
                        "field4": "",
                        "description": "INDESIT ASCIUGATRICE A POMPA DI CALORE YTM1081REU",
                        "grammage": 0,
                        "price": "329.00",
                        "price_label": "€ 329,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Asciugatrici",
                        "blueprint": {
                            "top": 60.588235294117645,
                            "left": 34.39662990196077,
                            "width": 32.46341911764706,
                            "height": 30.20671196618338
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_wS722fO_zlKmYh7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "605778",
                        "field1": "CANDY LAVATRICE",
                        "field2": "SLIM GVS4",
                        "field3": "137T3/1-01",
                        "field4": "",
                        "description": "CANDY LAVATRICE SLIM GVS4 137T3/1-01",
                        "grammage": 0,
                        "price": "249.00",
                        "price_label": "€ 249,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Lavatrici",
                        "blueprint": {
                            "top": 60.44117647058823,
                            "left": 67.53658088235294,
                            "width": 32.46341911764706,
                            "height": 30.353770789712794
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_RhzLWZr_krFTSjs.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": ["611221", "700576", "619285", "641192"] ,
                    }
                ]
            },
            {
                "number": 3,
                "image_file": "/media/interactive_flyer_87/pages/page_3_TDEPzkR.jpg",
                "products": [
                    {
                        "item_id": "659148",
                        "field1": "LG FRIGORIFERO",
                        "field2": "MULTIDOOR",
                        "field3": "GML844PZKZ",
                        "field4": "",
                        "description": "LG FRIGORIFERO MULTIDOOR GML844PZKZ",
                        "grammage": null,
                        "price": "1499.00",
                        "price_label": "€ 1499,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Frigoriferi",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 33.64950980392157,
                            "height": 31.383182554418674
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_FgiRYlX.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "691757",
                        "field1": "SAMSUNG FRIGORIFERO",
                        "field2": "DOPPIA PORTA",
                        "field3": "RT53K6655SL/ES",
                        "field4": "",
                        "description": "SAMSUNG FRIGORIFERO DOPPIA PORTA RT53K6655SL/ES",
                        "grammage": null,
                        "price": "799.00",
                        "price_label": "€ 799,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Frigoriferi",
                        "blueprint": {
                            "top": 0.0,
                            "left": 33.39301470588235,
                            "width": 33.64950980392157,
                            "height": 31.383182554418674
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_bPseGEI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "Video",
                                "data": "/media/interactive_flyer_87/video/rt53k.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "701592",
                        "field1": "SAMSUNG FRIGORIFERO",
                        "field2": "COMBINATO ",
                        "field3": "RB37J501MSA",
                        "field4": "",
                        "description": "SAMSUNG FRIGORIFERO COMBINATO  RB37J501MSA",
                        "grammage": null,
                        "price": "549.00",
                        "price_label": "€ 549,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Frigoriferi",
                        "blueprint": {
                            "top": 0.0,
                            "left": 66.35049019607841,
                            "width": 33.64950980392157,
                            "height": 31.383182554418674
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_d5p7w6S.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "757401",
                        "field1": "FINLUX PIANO COTTURA",
                        "field2": "DA INCASSO",
                        "field3": "FXBGH13X60",
                        "field4": "",
                        "description": "FINLUX PIANO COTTURA DA INCASSO FXBGH13X60",
                        "grammage": null,
                        "price": "77.40",
                        "price_label": "€ 77,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Piani Cottura",
                        "blueprint": {
                            "top": 31.323529411764703,
                            "left": 0.0,
                            "width": 33.64950980392157,
                            "height": 31.383182554418674
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_uQNuJeC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "653885",
                        "field1": "ELECTOLUX PIANO COTTURA",
                        "field2": "INDUZIONE",
                        "field3": "EHH6240I0K",
                        "field4": "",
                        "description": "ELECTOLUX PIANO COTTURA INDUZIONE EHH6240I0K",
                        "grammage": 0,
                        "price": "259.00",
                        "price_label": "€ 259,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Piani Cottura",
                        "blueprint": {
                            "top": 31.02941176470588,
                            "left": 32.389399509803916,
                            "width": 33.64950980392157,
                            "height": 31.383182554418674
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_mpTDnfu.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "776686",
                        "field1": "BEKO CONGELATORE",
                        "field2": "POZZETTO",
                        "field3": "HS210520",
                        "field4": "",
                        "description": "BEKO CONGELATORE POZZETTO HS210520",
                        "grammage": null,
                        "price": "183.20",
                        "price_label": "€ 183,20",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Congelatori",
                        "blueprint": {
                            "top": 30.441176470588236,
                            "left": 66.35049019607841,
                            "width": 33.64950980392157,
                            "height": 28.95671196618338
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_BND7CJu.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "725085",
                        "field1": "FINLUX FORNO",
                        "field2": "MULTIFUNZIONE INCASSO",
                        "field3": "FXB030N4TE4",
                        "field4": "",
                        "description": "FINLUX FORNO MULTIFUNZIONE INCASSO FXB030N4TE4",
                        "grammage": null,
                        "price": "89.40",
                        "price_label": "€ 89,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Forni",
                        "blueprint": {
                            "top": 61.838235294117645,
                            "left": 0.0,
                            "width": 33.19332107843137,
                            "height": 28.95671196618338
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_iOfeUm5.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "692933",
                        "field1": "GLEMGAS FORNO",
                        "field2": "INCASSO ",
                        "field3": "FOGFM52IXN",
                        "field4": "",
                        "description": "GLEMGAS FORNO INCASSO  FOGFM52IXN",
                        "grammage": 0,
                        "price": "174.30",
                        "price_label": "€ 174,30",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Forni",
                        "blueprint": {
                            "top": 62.05882352941176,
                            "left": 32.57187499999999,
                            "width": 33.19332107843137,
                            "height": 28.95671196618338
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_xghApHr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "799454",
                        "field1": "ELECTROLUX FRIGORIFERO",
                        "field2": "COMBINATO INCASSO",
                        "field3": "RNN2800A0Q",
                        "field4": "",
                        "description": "ELECTROLUX FRIGORIFERO COMBINATO INCASSO RNN2800A0Q",
                        "grammage": 0,
                        "price": "329.30",
                        "price_label": "€ 329,30",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Grandi Elettrodomestici",
                        "subcategory": "Frigoriferi",
                        "blueprint": {
                            "top": 58.823529411764696,
                            "left": 61.97107843137254,
                            "width": 38.028921568627446,
                            "height": 32.11847667206574
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_7jJasIW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 4,
                "image_file": "/media/interactive_flyer_87/pages/page_4_Sh57rla.jpg",
                "products": [
                    {
                        "item_id": "768644",
                        "field1": "PANASONIC 65\" ",
                        "field2": "TV OLED ULTRA HDR",
                        "field3": "TX-65GZ950",
                        "field4": "",
                        "description": "PANASONIC 65\"  TV OLED ULTRA HDR TX-65GZ950",
                        "grammage": null,
                        "price": "2199.00",
                        "price_label": "€ 2199,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Panasonic",
                        "blueprint": {
                            "top": 18.382352941176467,
                            "left": 0.3649509803921568,
                            "width": 18.41280637254902,
                            "height": 8.442006083830444
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_L3I6Dll_k34xlC1.png",
                                "cropped": true
                            }
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "633627",
                        "field1": "PANASONIC 55\"",
                        "field2": "TV OLED ULTRA HDR",
                        "field3": "TX-55GZ950",
                        "field4": "",
                        "description": "PANASONIC 55\" TV OLED ULTRA HDR TX-55GZ950",
                        "grammage": null,
                        "price": "1399.00",
                        "price_label": "€ 1399,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Panasonic",
                        "blueprint": {
                            "top": 18.676470588235293,
                            "left": 18.6125,
                            "width": 17.774142156862744,
                            "height": 8.442006083830444
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_L3I6Dll_k34xlC1.png",
                                "cropped": true
                            }
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "652718",
                        "field1": "LG TV 65\"",
                        "field2": "OLED65B9",
                        "field3": "",
                        "field4": "",
                        "description": "LG TV 65\" OLED65B9",
                        "grammage": 0,
                        "price": "1999.00",
                        "price_label": "€ 1999,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "LG",
                        "blueprint": {
                            "top": 16.544117647058822,
                            "left": 37.316237745098036,
                            "width": 17.774142156862744,
                            "height": 7.78024137794809
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_J0WavDf_u9OY5VJ.png",
                                "cropped": true
                            }
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "Video",
                                "data": "/media/interactive_flyer_87/video/lgoled.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "638849",
                        "field1": "LG TV 55\"",
                        "field2": "OLED55B9",
                        "field3": "",
                        "field4": "",
                        "description": "LG TV 55\" OLED55B9",
                        "grammage": 0,
                        "price": "1299.00",
                        "price_label": "€ 1299,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "LG",
                        "blueprint": {
                            "top": 16.470588235294116,
                            "left": 55.10759803921569,
                            "width": 17.22671568627451,
                            "height": 7.78024137794809
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_J0WavDf_u9OY5VJ.png",
                                "cropped": true
                            }
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "Video",
                                "data": "/media/interactive_flyer_87/video/lgoled.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "690474",
                        "field1": "GOPRO HERO 7 WHITE",
                        "field2": "ACTION CAMERA",
                        "field3": "",
                        "field4": "",
                        "description": "GOPRO HERO 7 WHITE ACTION CAMERA",
                        "grammage": 0,
                        "price": "169.90",
                        "price_label": "€ 169,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Fotografia e Videocamere",
                        "subcategory": "ActionCam",
                        "blueprint": {
                            "top": 2.2058823529411766,
                            "left": 72.53400735294116,
                            "width": 25.276286764705876,
                            "height": 28.221417848536326
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_t7kybpv_RoIJHFu.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "633957",
                        "field1": "LG TV LED 75\"",
                        "field2": "75UM7000PLA",
                        "field3": "",
                        "field4": "",
                        "description": "LG TV LED 75\" 75UM7000PLA",
                        "grammage": null,
                        "price": "899.00",
                        "price_label": "€ 899,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "LG",
                        "blueprint": {
                            "top": 29.705882352941178,
                            "left": 0.0,
                            "width": 36.77224264705882,
                            "height": 31.08906490735985
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_oRdIvxN_HcGn7lD.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "761695",
                        "field1": "LG TV LED 55\"",
                        "field2": "55SM8200PLA",
                        "field3": "",
                        "field4": "",
                        "description": "LG TV LED 55\" 55SM8200PLA",
                        "grammage": 0,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "LG",
                        "blueprint": {
                            "top": 29.705882352941178,
                            "left": 36.221384803921566,
                            "width": 36.77224264705882,
                            "height": 31.08906490735985
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_PHSEjSK_UBL2fG6.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "Video",
                                "data": "/media/interactive_flyer_87/video/lgled.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "646391",
                        "field1": "PANASONIC SOUNDBAR",
                        "field2": "SC-HTB8EG-K",
                        "field3": "",
                        "field4": "",
                        "description": "PANASONIC SOUNDBAR SC-HTB8EG-K",
                        "grammage": null,
                        "price": "69.90",
                        "price_label": "€ 69,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Panasonic",
                        "blueprint": {
                            "top": 30.95588235294117,
                            "left": 72.16905637254901,
                            "width": 27.830943627450974,
                            "height": 17.11847667206574
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_ZM6AWTJ_OFPw8Wy.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "692453",
                        "field1": "TELESYSTEM DECODER",
                        "field2": "UHD TS ULTRA",
                        "field3": "",
                        "field4": "",
                        "description": "TELESYSTEM DECODER UHD TS ULTRA",
                        "grammage": 0,
                        "price": "107.10",
                        "price_label": "€ 107,10",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 47.94117647058823,
                            "left": 72.16905637254901,
                            "width": 27.830943627450974,
                            "height": 19.83906490735985
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_SASm7Ik_SMjekLM.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "607226",
                        "field1": "LG TV LED 65\"",
                        "field2": "65UM7000PLA",
                        "field3": "",
                        "field4": "",
                        "description": "LG TV LED 65\" 65UM7000PLA",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "LG",
                        "blueprint": {
                            "top": 60.0,
                            "left": 0.0,
                            "width": 35.76862745098038,
                            "height": 30.94200608383044
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_pNHQ1Sz_UcZjIvA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "778458",
                        "field1": "SONY TV LED 55\"",
                        "field2": "KD55XG819GBAEP",
                        "field3": "",
                        "field4": "",
                        "description": "SONY TV LED 55\" KD55XG819GBAEP",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Sony",
                        "blueprint": {
                            "top": 59.92647058823529,
                            "left": 35.491482843137256,
                            "width": 38.23204656862744,
                            "height": 30.94200608383044
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_FnQrW7C_b8JR8rD.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "785896",
                        "field1": "GUARNIERI STAFFA",
                        "field2": "DOPPIO BRACCIO",
                        "field3": "",
                        "field4": "",
                        "description": "GUARNIERI STAFFA DOPPIO BRACCIO",
                        "grammage": null,
                        "price": "14.90",
                        "price_label": "€ 14,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 67.27941176470587,
                            "left": 73.81133578431371,
                            "width": 26.18866421568627,
                            "height": 23.442006083830446
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_2btVXZa_L5MBn80.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 5,
                "image_file": "/media/interactive_flyer_87/pages/page_5_kyOSjaL.jpg",
                "products": [
                    {
                        "item_id": "708941",
                        "field1": "SAMSUNG 65\"",
                        "field2": "Q70R",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG 65\" Q70R",
                        "grammage": null,
                        "price": "999.00",
                        "price_label": "€ 999,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 1.3235294117647058,
                            "left": 1.7335171568627452,
                            "width": 52.64761029411764,
                            "height": 29.324359025006913
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_UvEKTkQ_5a44LiE.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "616255",
                        "field1": "SAMSUNG 49\"",
                        "field2": "Q60R",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG 49\" Q60R",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 1.3235294117647058,
                            "left": 53.2828431372549,
                            "width": 46.71715686274509,
                            "height": 29.324359025006913
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_MrR9Wmf_vGt3cJI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "728647",
                        "field1": "LG TV LED 43\"",
                        "field2": "43UM7000PLA",
                        "field3": "",
                        "field4": "",
                        "description": "LG TV LED 43\" 43UM7000PLA",
                        "grammage": null,
                        "price": "299.00",
                        "price_label": "€ 299,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "LG",
                        "blueprint": {
                            "top": 30.882352941176467,
                            "left": 0.0,
                            "width": 46.71715686274509,
                            "height": 29.324359025006913
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_U8mBnEY_QCW4urX.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "673052",
                        "field1": "SAMSUNG TV LED 55\"",
                        "field2": "UE55RU7090",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG TV LED 55\" UE55RU7090",
                        "grammage": null,
                        "price": "399.00",
                        "price_label": "€ 399,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 31.02941176470588,
                            "left": 47.07867647058823,
                            "width": 52.738848039215675,
                            "height": 29.324359025006913
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_r6WxzGd_g8QqYAS.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "638742",
                        "field1": "BOLVA TV LED",
                        "field2": "LED3266",
                        "field3": "",
                        "field4": "",
                        "description": "BOLVA TV LED LED3266",
                        "grammage": null,
                        "price": "99.80",
                        "price_label": "€ 99,80",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": 100,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Bolva",
                        "blueprint": {
                            "top": 61.544117647058826,
                            "left": 0.0,
                            "width": 46.16973039215685,
                            "height": 29.324359025006913
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_4AgDIPR_Ix6U3Em.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "644970",
                        "field1": "MAJESTIC SPEAKER",
                        "field2": "BLUETOOTH",
                        "field3": "DJPB53SDAX",
                        "field4": "",
                        "description": "MAJESTIC SPEAKER BLUETOOTH DJPB53SDAX",
                        "grammage": null,
                        "price": "9.90",
                        "price_label": "€ 9,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Audio",
                        "subcategory": "Casse Bluetooth",
                        "blueprint": {
                            "top": 61.61764705882352,
                            "left": 47.07867647058823,
                            "width": 27.465992647058812,
                            "height": 29.324359025006913
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_z6GNY04_8XigtIC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "618371",
                        "field1": "GRUNDING",
                        "field2": "RADIO AM/FM",
                        "field3": "MINI62/SLOR",
                        "field4": "",
                        "description": "GRUNDING RADIO AM/FM MINI62/SLOR",
                        "grammage": null,
                        "price": "9.90",
                        "price_label": "€ 9,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Audio",
                        "subcategory": "Radio FM",
                        "blueprint": {
                            "top": 66.10294117647058,
                            "left": 73.81133578431373,
                            "width": 26.188664215686263,
                            "height": 24.839064907359855
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_HXUpLTR_fO0zPnJ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 6,
                "image_file": "/media/interactive_flyer_87/pages/page_6_6hhoZNu.jpg",
                "products": [
                    {
                        "item_id": "674634",
                        "field1": "NIKON D3500",
                        "field2": "FOTOCAMERA DIGITALE REFLEX",
                        "field3": "+ BORSA CASE LOGIC",
                        "field4": "",
                        "description": "NIKON D3500 FOTOCAMERA DIGITALE REFLEX + BORSA CASE LOGIC",
                        "grammage": 0,
                        "price": "349.00",
                        "price_label": "€ 349,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Fotografia e Videocamere",
                        "subcategory": "Nikon",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 35.40367647058822,
                            "height": 34.54494726030103
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_vHlsqCb_Louh3SG.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "740054",
                        "field1": "CANON EOS M100",
                        "field2": "FOTOCAMERA DIGITALE MIRRORLESS",
                        "field3": "",
                        "field4": "",
                        "description": "CANON EOS M100 FOTOCAMERA DIGITALE MIRRORLESS",
                        "grammage": 0,
                        "price": "349.00",
                        "price_label": "€ 349,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Fotografia e Videocamere",
                        "subcategory": "Canon",
                        "blueprint": {
                            "top": 0.0,
                            "left": 33.48425245098039,
                            "width": 33.76139705882352,
                            "height": 32.55965314265397
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_DUoOR46_oyHScJd.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "725409",
                        "field1": "POLAROID ORIGINALS",
                        "field2": "FOTOCAMERA ANALOGICA",
                        "field3": "ONESTEP 2 VF WHITE",
                        "field4": "",
                        "description": "POLAROID ORIGINALS FOTOCAMERA ANALOGICA ONESTEP 2 VF WHITE",
                        "grammage": null,
                        "price": "99.90",
                        "price_label": "€ 99,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Fotografia e Videocamere",
                        "subcategory": "Polaroid",
                        "blueprint": {
                            "top": 3.161764705882353,
                            "left": 65.78241421568629,
                            "width": 34.21758578431371,
                            "height": 29.912594319124555
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_5s8Xv9i_0D6HZ0j.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "706226",
                        "field1": "MICROSOFT XBOX ONE S",
                        "field2": "1 TB",
                        "field3": "+ STAR WARS FALLEN JEDI ORDER",
                        "field4": "",
                        "description": "MICROSOFT XBOX ONE S 1 TB + STAR WARS FALLEN JEDI ORDER",
                        "grammage": null,
                        "price": "199.00",
                        "price_label": "€ 199,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Console",
                        "blueprint": {
                            "top": 33.89705882352941,
                            "left": 1.3685661764705883,
                            "width": 32.849019607843125,
                            "height": 26.16259431912456
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_6GS9PRD_eMYyqc7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "738984",
                        "field1": "SONY PS4 500GB",
                        "field2": "+ 2 DUALSHOCK 4",
                        "field3": "+ VOUCHER FORTNITE",
                        "field4": "",
                        "description": "SONY PS4 500GB + 2 DUALSHOCK 4 + VOUCHER FORTNITE",
                        "grammage": 0,
                        "price": "229.00",
                        "price_label": "€ 229,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Console",
                        "blueprint": {
                            "top": 33.75,
                            "left": 35.035294117647055,
                            "width": 32.849019607843125,
                            "height": 26.16259431912456
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_qk2vtyt_WnY4EC7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "709077",
                        "field1": "CUFFIE PS4 GOLD WIRELESS",
                        "field2": "+ FORTNITE VCH",
                        "field3": "",
                        "field4": "",
                        "description": "CUFFIE PS4 GOLD WIRELESS + FORTNITE VCH",
                        "grammage": null,
                        "price": "59.90",
                        "price_label": "€ 59,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 33.014705882352935,
                            "left": 67.15098039215687,
                            "width": 32.849019607843125,
                            "height": 27.41259431912456
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_2SO7iik_Y1s3N0u.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "626267",
                        "field1": "SONY BUNDLE PLAYSTATION VR",
                        "field2": "+ PS CAMERA V2",
                        "field3": "+ 5 GIOCHI",
                        "field4": "",
                        "description": "SONY BUNDLE PLAYSTATION VR + PS CAMERA V2 + 5 GIOCHI",
                        "grammage": null,
                        "price": "229.00",
                        "price_label": "€ 229,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Console",
                        "blueprint": {
                            "top": 60.95588235294117,
                            "left": 0.0,
                            "width": 41.42536764705881,
                            "height": 29.912594319124555
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_ji50VNJ_XZMWFXN.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "601678",
                        "field1": "SONY PLAYSTATION CARD",
                        "field2": "12 MESI",
                        "field3": "",
                        "field4": "",
                        "description": "SONY PLAYSTATION CARD 12 MESI",
                        "grammage": null,
                        "price": "44.90",
                        "price_label": "€ 44,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 59.705882352941174,
                            "left": 40.9657475490196,
                            "width": 27.19227941176469,
                            "height": 31.23612373088926
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_hHUMXeB_QwEycMV.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "719618",
                        "field1": "SONY CONTROLLER WIFI PS4",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "SONY CONTROLLER WIFI PS4",
                        "grammage": null,
                        "price": "39.90",
                        "price_label": "€ 39,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ['blue', 'gold', 'silver', 'black', 'mimetic', 'white'],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 60.294117647058826,
                            "left": 67.33345588235295,
                            "width": 32.66654411764704,
                            "height": 30.72141784853632
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_LUaXG6U_eDODrxL.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 7,
                "image_file": "/media/interactive_flyer_87/pages/page_7_LP8Xw2T.jpg",
                "products": [
                    {
                        "item_id": "641331",
                        "field1": "KENWOOD COOKING CHEF",
                        "field2": "GOURMET KCC9068S",
                        "field3": "",
                        "field4": "",
                        "description": "KENWOOD COOKING CHEF GOURMET KCC9068S",
                        "grammage": 0,
                        "price": "799.00",
                        "price_label": "€ 799,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 33.67015931372548,
                            "height": 28.809653142653964
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_WqMvviw_q3najtm.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "769540",
                        "field1": "KENWOOD KITCHEN MACHINE",
                        "field2": "KVL6100S",
                        "field3": "",
                        "field4": "",
                        "description": "KENWOOD KITCHEN MACHINE KVL6100S",
                        "grammage": null,
                        "price": "299.00",
                        "price_label": "€ 299,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 0.0,
                            "left": 33.21053921568627,
                            "width": 34.035110294117636,
                            "height": 27.41259431912456
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_aFhfRNC_V22HmIN.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "Video",
                                "data": "/media/interactive_flyer_87/video/kenwood.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": "758299",
                        "field1": "MOULINEX CENTRIFUGA ",
                        "field2": "JU2000",
                        "field3": "",
                        "field4": "",
                        "description": "MOULINEX CENTRIFUGA  JU2000",
                        "grammage": 0,
                        "price": "34.90",
                        "price_label": "€ 34,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 0.0,
                            "left": 67.78964460784314,
                            "width": 32.210355392156856,
                            "height": 23.000829613242203
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_UwRt56Q_z6nkYjp.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "602911",
                        "field1": "NESCAFE' PICCOLO XS",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "NESCAFE' PICCOLO XS",
                        "grammage": 0,
                        "price": "59.90",
                        "price_label": "€ 59,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 28.52941176470588,
                            "left": 0.0,
                            "width": 37.95833333333332,
                            "height": 24.76553549559514
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_WfWcV6f_nJdVMOy.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "709084",
                        "field1": "DE LONGHI MACCHINA CAFFE'",
                        "field2": "ECP3531",
                        "field3": "",
                        "field4": "",
                        "description": "DE LONGHI MACCHINA CAFFE' ECP3531",
                        "grammage": 0,
                        "price": "79.90",
                        "price_label": "€ 79,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Macchine da Caffè",
                        "blueprint": {
                            "top": 27.05882352941176,
                            "left": 37.316237745098036,
                            "width": 28.013419117647047,
                            "height": 22.118476672065732
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_lXpHscL_pKh7qY3.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "793635",
                        "field1": "DE LONGHI MACCHINA CAFFE'",
                        "field2": "ECAM355",
                        "field3": "",
                        "field4": "",
                        "description": "DE LONGHI MACCHINA CAFFE' ECAM355",
                        "grammage": 0,
                        "price": "399.00",
                        "price_label": "€ 399,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Macchine da Caffè",
                        "blueprint": {
                            "top": 22.647058823529413,
                            "left": 65.69117647058823,
                            "width": 34.308823529411754,
                            "height": 28.956711966183377
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_T26561t_2dHPz4f.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "618437",
                        "field1": "LAVAZZA MACCHINA CAFFE'",
                        "field2": "IDOLA",
                        "field3": "",
                        "field4": "",
                        "description": "LAVAZZA MACCHINA CAFFE' IDOLA",
                        "grammage": null,
                        "price": "127.40",
                        "price_label": "€ 127,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Macchine da Caffè",
                        "blueprint": {
                            "top": 52.42647058823529,
                            "left": 0.0,
                            "width": 34.67377450980391,
                            "height": 18.147888436771613
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_HdORDfo_8WhTovA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "718790",
                        "field1": "BIALETTI MACCHINA CAFFE'",
                        "field2": "OPERA",
                        "field3": "",
                        "field4": "",
                        "description": "BIALETTI MACCHINA CAFFE' OPERA",
                        "grammage": 0,
                        "price": "39.90",
                        "price_label": "€ 39,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ['rosso', 'senape', 'bianco'],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Macchine da Caffè",
                        "blueprint": {
                            "top": 48.897058823529406,
                            "left": 35.58272058823529,
                            "width": 24.09019607843136,
                            "height": 21.309653142653964
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_S4Hdw6P_lR2Bn6s.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_87/products/opera.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_87/products/opera2.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_87/products/opera3.png",
                                "cropped": true
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "647858",
                        "field1": "SAMSUNG FORNO",
                        "field2": "MICROONDE ",
                        "field3": "MC28H5015AW",
                        "field4": "",
                        "description": "SAMSUNG FORNO MICROONDE  MC28H5015AW",
                        "grammage": 0,
                        "price": "147.50",
                        "price_label": "€ 147,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Forni Elettrici",
                        "blueprint": {
                            "top": 50.955882352941174,
                            "left": 59.21329656862745,
                            "width": 40.78670343137254,
                            "height": 19.986123730889258
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_SBOJVFn_aN1uBeF.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "728975",
                        "field1": "WHIRLPOOL MICROONDE",
                        "field2": "JQ 280 SL",
                        "field3": "",
                        "field4": "",
                        "description": "WHIRLPOOL MICROONDE JQ 280 SL",
                        "grammage": null,
                        "price": "179.00",
                        "price_label": "€ 179,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Forni Elettrici",
                        "blueprint": {
                            "top": 70.0,
                            "left": 0.0,
                            "width": 35.1299632352941,
                            "height": 21.01553549559514
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_DSJAZuh_XoLOJFi.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "771556",
                        "field1": "DELONGHI SFORNATUTTO",
                        "field2": "E03275",
                        "field3": "",
                        "field4": "",
                        "description": "DELONGHI SFORNATUTTO E03275",
                        "grammage": null,
                        "price": "159.40",
                        "price_label": "€ 159,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Forni Elettrici",
                        "blueprint": {
                            "top": 69.92647058823529,
                            "left": 34.761580882352945,
                            "width": 33.76139705882352,
                            "height": 21.01553549559514
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_DWSd3dh_fPUWtKd.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "637947",
                        "field1": "BORSA ACQUA CALDA",
                        "field2": "CASA COLLECTION",
                        "field3": "0039039",
                        "field4": "",
                        "description": "BORSA ACQUA CALDA CASA COLLECTION 0039039",
                        "grammage": 0,
                        "price": "5.90",
                        "price_label": "€ 5,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Alimentari",
                        "subcategory": "Colazione",
                        "blueprint": {
                            "top": 70.14705882352942,
                            "left": 70.34430147058823,
                            "width": 29.655698529411755,
                            "height": 20.794947260301022
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_gibSa2V_6Wfs2zu.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 8,
                "image_file": "/media/interactive_flyer_87/pages/page_8_bLVNz2z.jpg",
                "products": [
                    {
                        "item_id": "936383",
                        "field1": "TOGNANA MOKA",
                        "field2": "1 TAZZA",
                        "field3": "V443051MORU",
                        "field4": "",
                        "description": "TOGNANA MOKA 1 TAZZA V443051MORU",
                        "grammage": null,
                        "price": "4.90",
                        "price_label": "€ 4,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Macchine da Caffè",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 32.48406862745096,
                            "height": 23.014705882352942
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_Rx4Ooiq_oD0ZGpr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "976234",
                        "field1": "BIALETTI MOKA",
                        "field2": "3 TAZZE",
                        "field3": "ALLEGRA",
                        "field4": "",
                        "description": "BIALETTI MOKA 3 TAZZE ALLEGRA",
                        "grammage": null,
                        "price": "6.40",
                        "price_label": "€ 6,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Macchine da Caffè",
                        "blueprint": {
                            "top": 0.0,
                            "left": 28.648651960784314,
                            "width": 32.48406862745096,
                            "height": 23.014705882352942
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_cJ76TkZ_mlqqsd1.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "963107",
                        "field1": "TOGNANA BATTERIA 9 PZ",
                        "field2": "\"STELL\"",
                        "field3": "",
                        "field4": "",
                        "description": "TOGNANA BATTERIA 9 PZ \"STELL\"",
                        "grammage": null,
                        "price": "70.00",
                        "price_label": "€ 70,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 0.0,
                            "left": 57.388541666666654,
                            "width": 42.61145833333332,
                            "height": 23.014705882352942
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_hfiBzUi_6L4GCDd.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "959147",
                        "field1": "BIALETTI BATTERIA 8PZ",
                        "field2": "ESSENTIAL PETRA SILVER",
                        "field3": "",
                        "field4": "",
                        "description": "BIALETTI BATTERIA 8PZ ESSENTIAL PETRA SILVER",
                        "grammage": null,
                        "price": "52.40",
                        "price_label": "€ 52,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 22.5,
                            "left": 4.744362745098039,
                            "width": 43.615073529411745,
                            "height": 23.014705882352942
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_0m0NBOE_Cydmu3y.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "934919",
                        "field1": "CASA COLLECTION",
                        "field2": "BATTERIA 7PX",
                        "field3": "\"CORA\"",
                        "field4": "",
                        "description": "CASA COLLECTION BATTERIA 7PX \"CORA\"",
                        "grammage": null,
                        "price": "48.90",
                        "price_label": "€ 48,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 23.014705882352942,
                            "left": 51.184375,
                            "width": 48.81562499999998,
                            "height": 23.014705882352942
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_LAVFid8_o0S7P5y.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "981072",
                        "field1": "TOGNANA SET ",
                        "field2": "SET PADELLE 24CM",
                        "field3": "+ 28CM OPAL BLACK",
                        "field4": "",
                        "description": "TOGNANA SET  SET PADELLE 24CM + 28CM OPAL BLACK",
                        "grammage": null,
                        "price": "17.40",
                        "price_label": "€ 17,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 45.95588235294117,
                            "left": 0.0,
                            "width": 32.84901960784312,
                            "height": 21.102941176470587
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_CAheKfT_iCDI7Lq.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "949791",
                        "field1": "BIALETTI SET",
                        "field2": "PADELLA 24CM",
                        "field3": "+ 28CM PETRA SILVER",
                        "field4": "BISESSENTIAL24-28",
                        "description": "BIALETTI SET PADELLA 24CM + 28CM PETRA SILVER BISESSENTIAL24-28",
                        "grammage": null,
                        "price": "16.00",
                        "price_label": "€ 16,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 45.36764705882352,
                            "left": 33.119301470588226,
                            "width": 32.84901960784312,
                            "height": 21.985294117647054
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_XotLlMq_MqCmFLs.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "967088",
                        "field1": "BIALETTI PADELLA",
                        "field2": "PROFESSIONALE DA 36CM",
                        "field3": "PADELLAFAMPRO36CM",
                        "field4": "",
                        "description": "BIALETTI PADELLA PROFESSIONALE DA 36CM PADELLAFAMPRO36CM",
                        "grammage": null,
                        "price": "25.40",
                        "price_label": "€ 25,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 46.54411764705881,
                            "left": 66.32984068627451,
                            "width": 30.476838235294103,
                            "height": 19.999999999999996
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_2EoqudK_77lt7he.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "951838",
                        "field1": "CASA COLLECTION",
                        "field2": "STAMPI PER DOLCI",
                        "field3": "VARIE DIMENSIONI",
                        "field4": "",
                        "description": "CASA COLLECTION STAMPI PER DOLCI VARIE DIMENSIONI",
                        "grammage": null,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 66.76470588235294,
                            "left": 1.4598039215686271,
                            "width": 30.9330269607843,
                            "height": 24.044117647058822
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_CqT5g3O_40bM4XJ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "942367",
                        "field1": "TOGNANA SERVIZIO PIATTI",
                        "field2": "DA 18PZ \"LEDA\"",
                        "field3": "OM070185447",
                        "field4": "",
                        "description": "TOGNANA SERVIZIO PIATTI DA 18PZ \"LEDA\" OM070185447",
                        "grammage": null,
                        "price": "32.40",
                        "price_label": "€ 32,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Alimentari",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 66.47058823529412,
                            "left": 33.39301470588235,
                            "width": 32.575306372549,
                            "height": 24.338235294117645
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_qLlxcio_kcbOfmU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "946573",
                        "field1": "CASA COLLECTION MACCHINA PASTA",
                        "field2": "0040550",
                        "field3": "",
                        "field4": "",
                        "description": "CASA COLLECTION MACCHINA PASTA 0040550",
                        "grammage": null,
                        "price": "15.40",
                        "price_label": "€ 15,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 66.02941176470587,
                            "left": 64.87003676470589,
                            "width": 35.1299632352941,
                            "height": 25.147058823529413
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_RrTUF5g_b7mmncx.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 9,
                "image_file": "/media/interactive_flyer_87/pages/page_9_ADDps77.jpg",
                "products": [
                    {
                        "item_id": "984605",
                        "field1": "RUSSEL HOBBS ",
                        "field2": "BISTECCHIERA",
                        "field3": "5 PORZIONI 25040",
                        "field4": "",
                        "description": "RUSSEL HOBBS  BISTECCHIERA 5 PORZIONI 25040",
                        "grammage": null,
                        "price": "47.90",
                        "price_label": "€ 47,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 34.305392156862744,
                            "height": 24.117647058823525
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_HzRNcie_3eu2tiU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "940826",
                        "field1": "RUSSEL HOBBS BOLLITORE",
                        "field2": "1,7 LT 20417",
                        "field3": "",
                        "field4": "",
                        "description": "RUSSEL HOBBS BOLLITORE 1,7 LT 20417",
                        "grammage": null,
                        "price": "40.40",
                        "price_label": "€ 40,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 1.8382352941176467,
                            "left": 33.30177696078431,
                            "width": 32.48063725490196,
                            "height": 24.117647058823525
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_W97ckBZ_RpRTbuU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "945014",
                        "field1": "IMETEC MACCHINA PER PANE ",
                        "field2": "\"ZERO-GLU\" 7815",
                        "field3": "",
                        "field4": "",
                        "description": "IMETEC MACCHINA PER PANE  \"ZERO-GLU\" 7815",
                        "grammage": null,
                        "price": "152.90",
                        "price_label": "€ 152,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Preparazione Cibo",
                        "blueprint": {
                            "top": 0.0,
                            "left": 67.51936274509804,
                            "width": 32.48063725490196,
                            "height": 26.76470588235294
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_0KK8fBw_u6g9Kk2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "983880",
                        "field1": "BRAUN RASOIO ELETTRICO",
                        "field2": "SERIES 9242S",
                        "field3": "",
                        "field4": "",
                        "description": "BRAUN RASOIO ELETTRICO SERIES 9242S",
                        "grammage": null,
                        "price": "179.00",
                        "price_label": "€ 179,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 25.588235294117645,
                            "left": 0.0,
                            "width": 40.9657475490196,
                            "height": 31.470588235294116
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_JemrpH2_daidF6R.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "961772",
                        "field1": "PHILIPS RASOIO ELETTRICO",
                        "field2": "S5630/41",
                        "field3": "",
                        "field4": "",
                        "description": "PHILIPS RASOIO ELETTRICO S5630/41",
                        "grammage": null,
                        "price": "99.99",
                        "price_label": "€ 99,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 25.51470588235294,
                            "left": 34.39662990196077,
                            "width": 40.78327205882353,
                            "height": 31.470588235294116
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_lKBgNfv_H27idk4.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "952464",
                        "field1": "PANASONIC RASOIO ELETTRICO",
                        "field2": "ES-SL33-S503",
                        "field3": "",
                        "field4": "",
                        "description": "PANASONIC RASOIO ELETTRICO ES-SL33-S503",
                        "grammage": null,
                        "price": "29.99",
                        "price_label": "€ 29,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 26.323529411764703,
                            "left": 68.15802696078431,
                            "width": 31.84197303921568,
                            "height": 29.705882352941178
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_xXayKj8_ZAB1Gft.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "967799",
                        "field1": "BRAUN REGOLABARBA",
                        "field2": "MULTIGROMMING",
                        "field3": "MGK5060",
                        "field4": "",
                        "description": "BRAUN REGOLABARBA MULTIGROMMING MGK5060",
                        "grammage": null,
                        "price": "39.99",
                        "price_label": "€ 39,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 57.205882352941174,
                            "left": 0.0,
                            "width": 37.225,
                            "height": 33.67647058823529
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_OKYuoPg_WigaGPC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "969476",
                        "field1": "REMINGTON RASOIO",
                        "field2": "RIFINITORE CORPO",
                        "field3": "BHT250",
                        "field4": "",
                        "description": "REMINGTON RASOIO RIFINITORE CORPO BHT250",
                        "grammage": null,
                        "price": "24.50",
                        "price_label": "€ 24,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 57.05882352941176,
                            "left": 36.03890931372549,
                            "width": 28.83112745098039,
                            "height": 33.67647058823529
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_6B5Loov_r7LBvPe.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "971443",
                        "field1": "ORAL-B SPAZZOLINO ELETTRICO",
                        "field2": "SMART 4500N",
                        "field3": "",
                        "field4": "",
                        "description": "ORAL-B SPAZZOLINO ELETTRICO SMART 4500N",
                        "grammage": null,
                        "price": "59.99",
                        "price_label": "€ 59,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 57.205882352941174,
                            "left": 61.58547794117647,
                            "width": 38.414522058823515,
                            "height": 33.67647058823529
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_FvJO4Rz_Q4ek2jx.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 10,
                "image_file": "/media/interactive_flyer_87/pages/page_10_fQpktxR.jpg",
                "products": [
                    {
                        "item_id": "937835",
                        "field1": "BRAUN EPILATORE",
                        "field2": "SILK-EPIL 9/870",
                        "field3": "SENSOSMART",
                        "field4": "",
                        "description": "BRAUN EPILATORE SILK-EPIL 9/870 SENSOSMART",
                        "grammage": null,
                        "price": "99.99",
                        "price_label": "€ 99,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 32.20692401960784,
                            "height": 31.323529411764703
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_cEec9bR_0wTKmVo.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "956938",
                        "field1": "REMINGTON EPILATORE",
                        "field2": "WDF5030 SMOOTH&SILKY",
                        "field3": "",
                        "field4": "",
                        "description": "REMINGTON EPILATORE WDF5030 SMOOTH&SILKY",
                        "grammage": null,
                        "price": "35.60",
                        "price_label": "€ 35,60",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 0.0,
                            "left": 32.82021771599265,
                            "width": 32.66311274509803,
                            "height": 30.51470588235294
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_c1IgaXa_EawfPZt.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "951372",
                        "field1": "REMINGTON FERRO ARRICCIACAPELLI",
                        "field2": "CI66525",
                        "field3": "",
                        "field4": "",
                        "description": "REMINGTON FERRO ARRICCIACAPELLI CI66525",
                        "grammage": null,
                        "price": "30.50",
                        "price_label": "€ 30,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 0.0,
                            "left": 67.33688725490195,
                            "width": 32.66311274509803,
                            "height": 30.51470588235294
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_f0VUpF0_kjPiBS3.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "954393",
                        "field1": "ROWENTA SCOPA RICARICABILE",
                        "field2": "X-PERT ESSENTIAL 260 RH7324",
                        "field3": "",
                        "field4": "",
                        "description": "ROWENTA SCOPA RICARICABILE X-PERT ESSENTIAL 260 RH7324",
                        "grammage": null,
                        "price": "149.00",
                        "price_label": "€ 149,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 29.117647058823525,
                            "left": 0.0,
                            "width": 35.491482843137256,
                            "height": 32.05882352941176
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_I38W3LF_RpehsWG.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "959807",
                        "field1": "ROWENTA SISTEMA STIRANTE",
                        "field2": "POWER STEAM VR8215",
                        "field3": "",
                        "field4": "",
                        "description": "ROWENTA SISTEMA STIRANTE POWER STEAM VR8215",
                        "grammage": null,
                        "price": "99.99",
                        "price_label": "€ 99,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Stiro e Cucito",
                        "blueprint": {
                            "top": 30.220588235294116,
                            "left": 33.39301470588235,
                            "width": 34.2141544117647,
                            "height": 30.441176470588236
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_Z9xZuwa_S89gkSU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "955547",
                        "field1": "ROWENTA FERRO DA STIRO",
                        "field2": "A VAPORE",
                        "field3": "DW9220",
                        "field4": "",
                        "description": "ROWENTA FERRO DA STIRO A VAPORE DW9220",
                        "grammage": null,
                        "price": "69.99",
                        "price_label": "€ 69,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Stiro e Cucito",
                        "blueprint": {
                            "top": 30.588235294117645,
                            "left": 67.33688725490195,
                            "width": 32.66311274509803,
                            "height": 30.441176470588236
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_5VkaHZL_oTYxooA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "965771",
                        "field1": "IROBOT ROOMBA 615",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "IROBOT ROOMBA 615",
                        "grammage": null,
                        "price": "169.00",
                        "price_label": "€ 169,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 60.588235294117645,
                            "left": 0.0,
                            "width": 32.66311274509803,
                            "height": 30.441176470588236
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_XYLzGYB_ASK46el.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "968621",
                        "field1": "IMETEC ECOEXTREME PRO",
                        "field2": "++ANIMAL CARE C2 200",
                        "field3": "",
                        "field4": "",
                        "description": "IMETEC ECOEXTREME PRO ++ANIMAL CARE C2 200",
                        "grammage": null,
                        "price": "59.99",
                        "price_label": "€ 59,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 60.44117647058823,
                            "left": 31.750735294117646,
                            "width": 34.94405637254902,
                            "height": 30.441176470588236
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_jZkCiZs_4ucbM8d.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "922675",
                        "field1": "ARIETE ASPIRAPOLVERE",
                        "field2": "SENZA SACCO",
                        "field3": "279/4",
                        "field4": "",
                        "description": "ARIETE ASPIRAPOLVER SENZA SACCO 279/4",
                        "grammage": null,
                        "price": "39.99",
                        "price_label": "€ 39,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Piccoli Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 60.36764705882352,
                            "left": 66.24203431372548,
                            "width": 33.7579656862745,
                            "height": 30.441176470588236
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_r0UkvOx_uCHo55v.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 11,
                "image_file": "/media/interactive_flyer_87/pages/page_11_2NDPfk6.jpg",
                "products": [
                    {
                        "item_id": "959618",
                        "field1": "MICROSOFT SURFACE PRO 7",
                        "field2": "2 IN 1 ",
                        "field3": "128 GB",
                        "field4": "",
                        "description": "MICROSOFT SURFACE PRO 7 2 IN 1  128 GB",
                        "grammage": null,
                        "price": "799.00",
                        "price_label": "€ 799,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 36.40729166666665,
                            "height": 23.455882352941178
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_U3HMwgY_rBNEyjp.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "973637",
                        "field1": "ASUS NOTEBOOK ",
                        "field2": "RX571GB-BQ257T",
                        "field3": "",
                        "field4": "",
                        "description": "ASUS NOTEBOOK  RX571GB-BQ257T",
                        "grammage": null,
                        "price": "699.00",
                        "price_label": "€ 699,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 0.0,
                            "left": 33.7579656862745,
                            "width": 34.30882352941175,
                            "height": 23.455882352941178
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_eHxmypr_6chc0T5.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "939734",
                        "field1": "LENOVO IDEAPAD 330-15IKB",
                        "field2": "81DE01MYIX",
                        "field3": "",
                        "field4": "",
                        "description": "LENOVO IDEAPAD 330-15IKB 81DE01MYIX",
                        "grammage": null,
                        "price": "569.00",
                        "price_label": "€ 569,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 0.0,
                            "left": 65.69117647058825,
                            "width": 34.30882352941175,
                            "height": 23.455882352941178
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_HWsKDar_yNIjyXl.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "947269",
                        "field1": "HP PAVILION ",
                        "field2": "15-CW1073NL",
                        "field3": "",
                        "field4": "",
                        "description": "HP PAVILION  15-CW1073NL",
                        "grammage": null,
                        "price": "549.00",
                        "price_label": "€ 549,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 23.235294117647058,
                            "left": 0.0,
                            "width": 34.30882352941175,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_ADqw5EM_v7hWCVE.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "952190",
                        "field1": "LENOVO IDEAPAD",
                        "field2": "S340-15IWL/81N800KVIX",
                        "field3": "",
                        "field4": "",
                        "description": "LENOVO IDEAPAD S340-15IWL/81N800KVIX",
                        "grammage": null,
                        "price": "499.00",
                        "price_label": "€ 499,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 23.161764705882355,
                            "left": 33.7579656862745,
                            "width": 33.30520833333332,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_WJpYk9S_Ce5jBN5.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "922401",
                        "field1": "ASUS F507MA-BR376T",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "ASUS F507MA-BR376T",
                        "grammage": null,
                        "price": "299.00",
                        "price_label": "€ 299,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 23.382352941176467,
                            "left": 66.69479166666667,
                            "width": 33.30520833333332,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_ZYUoe2n_DG1qSu9.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "933921",
                        "field1": "HP 14-DK0030NL",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "HP 14-DK0030NL",
                        "grammage": null,
                        "price": "299.00",
                        "price_label": "€ 299,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 45.588235294117645,
                            "left": 0.0,
                            "width": 34.03511029411763,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_5rL4JHE_f65FNwL.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "937602",
                        "field1": "LENOVO IDEAPAD",
                        "field2": "S130-14IGM",
                        "field3": "81J200D1IX",
                        "field4": "",
                        "description": "LENOVO IDEAPAD S130-14IGM 81J200D1IX",
                        "grammage": null,
                        "price": "189.00",
                        "price_label": "€ 189,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 45.588235294117645,
                            "left": 33.48425245098039,
                            "width": 34.03511029411763,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_Ao4UVNO_MiEj2uC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "987803",
                        "field1": "LENOVO IDEACENTRE",
                        "field2": "720-18APR / 90HY002EIX",
                        "field3": "",
                        "field4": "",
                        "description": "LENOVO IDEACENTRE 720-18APR / 90HY002EIX",
                        "grammage": null,
                        "price": "449.00",
                        "price_label": "€ 449,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Pc Desktop",
                        "blueprint": {
                            "top": 45.44117647058823,
                            "left": 65.96488970588237,
                            "width": 34.03511029411763,
                            "height": 23.455882352941178
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_R6xLWBA_i8g2AMc.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "937551",
                        "field1": "SAMSUNG GALAXY TAB S5E LTE",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG GALAXY TAB S5E LTE",
                        "grammage": null,
                        "price": "449.00",
                        "price_label": "€ 449,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Tablet",
                        "blueprint": {
                            "top": 67.42647058823529,
                            "left": 0.0,
                            "width": 34.03511029411763,
                            "height": 23.455882352941178
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_wRYtHrF_eX2lrG4.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "924462",
                        "field1": "SAMSUNG GALAXY TAB A 10.1\"",
                        "field2": "TABLET WIFI",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG GALAXY TAB A 10.1\" TABLET WIFI",
                        "grammage": null,
                        "price": "169.00",
                        "price_label": "€ 169,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Tablet",
                        "blueprint": {
                            "top": 67.5,
                            "left": 32.93682598039215,
                            "width": 34.03511029411763,
                            "height": 23.455882352941178
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_6ZMLeUW_BCjXmqN.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "937974",
                        "field1": "LENOVO TAB E10",
                        "field2": "ZA470043SE",
                        "field3": "",
                        "field4": "",
                        "description": "LENOVO TAB E10 ZA470043SE",
                        "grammage": null,
                        "price": "99.99",
                        "price_label": "€ 99,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Tablet",
                        "blueprint": {
                            "top": 69.04411764705881,
                            "left": 67.42469362745099,
                            "width": 31.024264705882334,
                            "height": 21.249999999999996
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_7eqGqEj_hRBeM8S.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 12,
                "image_file": "/media/interactive_flyer_87/pages/page_12_OtIU2Of.jpg",
                "products": [
                    {
                        "item_id": "935618",
                        "field1": "ESPON MULTIFUNZIONE",
                        "field2": "INKJET ECOTANK ",
                        "field3": "ET-2750",
                        "field4": "",
                        "description": "ESPON MULTIFUNZIONE INKJET ECOTANK  ET-2750",
                        "grammage": null,
                        "price": "179.00",
                        "price_label": "€ 179,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Stampanti",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 33.39644607843135,
                            "height": 21.323529411764707
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_3nFFUY7_HPTROP4.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "983656",
                        "field1": "ESPON MULTIFUNZIONE",
                        "field2": "INKJET WORKFORCE",
                        "field3": "PRO WF-7715DWF",
                        "field4": "",
                        "description": "ESPON MULTIFUNZIONE INKJET WORKFORCE PRO WF-7715DWF",
                        "grammage": null,
                        "price": "139.00",
                        "price_label": "€ 139,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Stampanti",
                        "blueprint": {
                            "top": 0.0,
                            "left": 32.66311274509803,
                            "width": 34.217585784313705,
                            "height": 21.985294117647054
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_0F00XVH_0MoPhag.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "975119",
                        "field1": "HP LASER ",
                        "field2": "MFP 135A",
                        "field3": "",
                        "field4": "",
                        "description": "HP LASER  MFP 135A",
                        "grammage": null,
                        "price": "89.99",
                        "price_label": "€ 89,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Stampanti",
                        "blueprint": {
                            "top": 0.0,
                            "left": 66.42107843137255,
                            "width": 33.578921568627436,
                            "height": 21.985294117647054
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_jq5ttrZ_f9k668I.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "982871",
                        "field1": "EPSON MULTIFUNZIONE",
                        "field2": "INKJET EXPRESSION PREMIUM",
                        "field3": "XP-6105",
                        "field4": "",
                        "description": "EPSON MULTIFUNZIONE INKJET EXPRESSION PREMIUM XP-6105",
                        "grammage": null,
                        "price": "69.99",
                        "price_label": "€ 69,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Stampanti",
                        "blueprint": {
                            "top": 22.647058823529413,
                            "left": 0.0,
                            "width": 33.578921568627436,
                            "height": 21.985294117647054
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_4UlFI2R_32n47eh.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "939106",
                        "field1": "EPSON MULTIFUNZIONE",
                        "field2": "INKJET WORKFORCE",
                        "field3": "WF-2630WF",
                        "field4": "",
                        "description": "EPSON MULTIFUNZIONE INKJET WORKFORCE WF-2630WF",
                        "grammage": null,
                        "price": "44.99",
                        "price_label": "€ 44,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Stampanti",
                        "blueprint": {
                            "top": 21.691176470588232,
                            "left": 33.02806372549019,
                            "width": 33.76139705882351,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_BOKkdWZ_aHA1Sq1.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "975587",
                        "field1": "HP ALL IN ONE OFFIJET",
                        "field2": "3833",
                        "field3": "",
                        "field4": "",
                        "description": "HP ALL IN ONE OFFIJET 3833",
                        "grammage": null,
                        "price": "39.99",
                        "price_label": "€ 39,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Stampanti",
                        "blueprint": {
                            "top": 21.838235294117645,
                            "left": 66.23860294117647,
                            "width": 33.76139705882351,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_rjMOeHk_d7old02.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "986536",
                        "field1": "CANON MULTIFUNZIONE",
                        "field2": "INKJET PIXMA TR4550",
                        "field3": "",
                        "field4": "",
                        "description": "CANON MULTIFUNZIONE INKJET PIXMA TR4550",
                        "grammage": null,
                        "price": "29.99",
                        "price_label": "€ 29,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Stampanti",
                        "blueprint": {
                            "top": 44.411764705882355,
                            "left": 0.0,
                            "width": 33.76139705882351,
                            "height": 22.132352941176467
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_4B6ymTL_cFM6J7I.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "928572",
                        "field1": "NORTON 360",
                        "field2": "DELUXE",
                        "field3": "",
                        "field4": "",
                        "description": "NORTON 360 DELUXE",
                        "grammage": null,
                        "price": "29.99",
                        "price_label": "€ 29,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Software",
                        "blueprint": {
                            "top": 44.48529411764705,
                            "left": 28.466176470588234,
                            "width": 21.900490196078415,
                            "height": 22.132352941176467
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_UStkImb_8NrSkwn.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "966372",
                        "field1": "OFFICE 365 PERSONAL",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "OFFICE 365 PERSONAL",
                        "grammage": null,
                        "price": "34.99",
                        "price_label": "€ 34,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Software",
                        "blueprint": {
                            "top": 43.97058823529411,
                            "left": 48.903431372549015,
                            "width": 29.290747549019592,
                            "height": 22.573529411764703
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_WWeX0Zd_GkESqHP.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "925692",
                        "field1": "FRITZ! MODEM",
                        "field2": "ROUTER FRITZ!",
                        "field3": "BOX 7530",
                        "field4": "",
                        "description": "FRITZ! MODEM ROUTER FRITZ! BOX 7530",
                        "grammage": null,
                        "price": "99.99",
                        "price_label": "€ 99,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Internet",
                        "blueprint": {
                            "top": 47.573529411764696,
                            "left": 73.9938112745098,
                            "width": 26.00618872549018,
                            "height": 20.36764705882353
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_vZNIgxM_jX4BehD.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "920610",
                        "field1": "MEDIACOM TABLET",
                        "field2": "SP7CY",
                        "field3": "",
                        "field4": "",
                        "description": "MEDIACOM TABLET SP7CY",
                        "grammage": null,
                        "price": "49.90",
                        "price_label": "€ 49,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Tablet",
                        "blueprint": {
                            "top": 67.5735294117647,
                            "left": 73.26390931372549,
                            "width": 26.736090686274494,
                            "height": 23.235294117647058
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_GRHxG4K_aVah1Tk.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 13,
                "image_file": "/media/interactive_flyer_87/pages/page_13_baaPipu.jpg",
                "products": [
                    {
                        "item_id": "959970",
                        "field1": "EZVIZ IP CAMERA",
                        "field2": "MOTORIZZATA",
                        "field3": "C6C720P",
                        "field4": "",
                        "description": "EZVIZ IP CAMERA MOTORIZZATA C6C720P",
                        "grammage": null,
                        "price": "39.90",
                        "price_label": "€ 39,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Sorveglianza",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 33.852634803921546,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_CfJM2vM_M7SfGpA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "956044",
                        "field1": "EZVIZ EZCUBE",
                        "field2": "IP CAMERA HD",
                        "field3": "",
                        "field4": "",
                        "description": "EZVIZ EZCUBE IP CAMERA HD",
                        "grammage": null,
                        "price": "24.90",
                        "price_label": "€ 24,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Sorveglianza",
                        "blueprint": {
                            "top": 0.0,
                            "left": 33.119301470588226,
                            "width": 31.75416666666665,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_LhfdsPX_u4W3jZK.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "948007",
                        "field1": "TPLINK ROUTER",
                        "field2": "POWERLINE",
                        "field3": "TL-WPA4221KIT",
                        "field4": "",
                        "description": "TPLINK ROUTER POWERLINE TL-WPA4221KIT",
                        "grammage": null,
                        "price": "35.90",
                        "price_label": "€ 35,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Internet",
                        "blueprint": {
                            "top": 0.0,
                            "left": 65.14374999999998,
                            "width": 34.85624999999998,
                            "height": 22.5
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_7kF2yuO_NWB6ekO.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "951797",
                        "field1": "STRONG RIPETITORE WIFI",
                        "field2": "REP300V2",
                        "field3": "",
                        "field4": "",
                        "description": "STRONG RIPETITORE WIFI REP300V2",
                        "grammage": null,
                        "price": "9.90",
                        "price_label": "€ 9,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Internet",
                        "blueprint": {
                            "top": 22.132352941176467,
                            "left": 0.0,
                            "width": 33.031495098039194,
                            "height": 22.647058823529413
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_AoZxZHK_eMwmEtO.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "976039",
                        "field1": "XTREME CUFFIA",
                        "field2": "+ MICROFONO",
                        "field3": "X22PRO",
                        "field4": "",
                        "description": "XTREME CUFFIA + MICROFONO X22PRO",
                        "grammage": null,
                        "price": "7.90",
                        "price_label": "€ 7,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Audio",
                        "subcategory": "Cuffie",
                        "blueprint": {
                            "top": 22.205882352941178,
                            "left": 33.940441176470586,
                            "width": 30.65931372549018,
                            "height": 22.647058823529413
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_9dV1eWu_saiuI1N.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "924024",
                        "field1": "XTREME CUFFIA",
                        "field2": "CON ARCHI RICHIUDEVOLI",
                        "field3": "33668",
                        "field4": "",
                        "description": "XTREME CUFFIA CON ARCHI RICHIUDEVOLI 33668",
                        "grammage": null,
                        "price": "3.90",
                        "price_label": "€ 3,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Audio",
                        "subcategory": "Cuffie",
                        "blueprint": {
                            "top": 22.205882352941178,
                            "left": 64.14013480392155,
                            "width": 35.85986519607842,
                            "height": 22.647058823529413
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_hECHN5K_ivv0XJx.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "949842",
                        "field1": "TRUST GAMING CHAIR",
                        "field2": "GXT 705 RYON",
                        "field3": "",
                        "field4": "",
                        "description": "TRUST GAMING CHAIR GXT 705 RYON",
                        "grammage": null,
                        "price": "99.99",
                        "price_label": "€ 99,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 44.48529411764705,
                            "left": 0.0,
                            "width": 34.12634803921567,
                            "height": 23.970588235294116
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_t9N7lbN_CWe9DoR.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "965242",
                        "field1": "TRUST GAMING HEADSET",
                        "field2": "GXT430 IRONN",
                        "field3": "",
                        "field4": "",
                        "description": "TRUST GAMING HEADSET GXT430 IRONN",
                        "grammage": null,
                        "price": "29.99",
                        "price_label": "€ 29,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Audio",
                        "subcategory": "Cuffie",
                        "blueprint": {
                            "top": 44.48529411764705,
                            "left": 33.7579656862745,
                            "width": 34.12634803921567,
                            "height": 23.970588235294116
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_2151uwM_FDnY5n0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "931865",
                        "field1": "TRUST GAMING KEYBOARD",
                        "field2": "GXT 830RW-C AVONN",
                        "field3": "",
                        "field4": "",
                        "description": "TRUST GAMING KEYBOARD GXT 830RW-C AVONN",
                        "grammage": null,
                        "price": "19.99",
                        "price_label": "€ 19,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 44.63235294117647,
                            "left": 67.42469362745099,
                            "width": 32.575306372549,
                            "height": 23.970588235294116
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_sQfoyLg_9uM4Uj3.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "926148",
                        "field1": "GOOGLE NEST HUB",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "GOOGLE NEST HUB",
                        "grammage": null,
                        "price": "79.99",
                        "price_label": "€ 79,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Audio",
                        "subcategory": "Smart Audio",
                        "blueprint": {
                            "top": 68.60294117647058,
                            "left": 1.7335171568627554,
                            "width": 28.8345588235294,
                            "height": 22.352941176470587
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_RCYsk8a_lTQvtVs.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "942188",
                        "field1": "GOOGLE HOMW",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "GOOGLE HOMW",
                        "grammage": null,
                        "price": "59.99",
                        "price_label": "€ 59,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Audio",
                        "subcategory": "Smart Audio",
                        "blueprint": {
                            "top": 68.60294117647058,
                            "left": 23.630575980392166,
                            "width": 27.192279411764687,
                            "height": 22.352941176470587
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_0VKXpnJ_O52214W.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "944056",
                        "field1": "GOOGLE HOME MINI",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "GOOGLE HOME MINI",
                        "grammage": null,
                        "price": "19.99",
                        "price_label": "€ 19,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Audio",
                        "subcategory": "Smart Audio",
                        "blueprint": {
                            "top": 68.74999999999999,
                            "left": 44.524019607843144,
                            "width": 27.192279411764687,
                            "height": 22.352941176470587
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_kXVc2IV_x1bMOO8.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "930930",
                        "field1": "GOOGLE CHROMECAST",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "GOOGLE CHROMECAST",
                        "grammage": null,
                        "price": "29.99",
                        "price_label": "€ 29,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Accessori",
                        "blueprint": {
                            "top": 68.60294117647058,
                            "left": 68.51954656862746,
                            "width": 29.564460784313713,
                            "height": 22.205882352941178
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_bvBNf81_6P7yF7u.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 14,
                "image_file": "/media/interactive_flyer_87/pages/page_14_HSMOkfm.jpg",
                "products": [
                    
                    {
                        "item_id": "943975",
                        "field1": "APPLE IPHONE XS ",
                        "field2": "64GB",
                        "field3": "",
                        "field4": "",
                        "description": "APPLE IPHONE XS  64GB",
                        "grammage": null,
                        "price": "799.00",
                        "price_label": "€ 799,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Apple",
                        "blueprint": {
                            "top": 5.955882352941176,
                            "left": 12.590808823529409,
                            "width": 17.60888480392157,
                            "height": 8.602941176470589
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_aecJMLj.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "952271",
                        "field1": "APPLE IPHONE XS",
                        "field2": "256GB",
                        "field3": "",
                        "field4": "",
                        "description": "APPLE IPHONE XS 256GB",
                        "grammage": null,
                        "price": "959.00",
                        "price_label": "€ 959,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Apple",
                        "blueprint": {
                            "top": 15.441176470588234,
                            "left": 12.499571078431371,
                            "width": 17.60888480392157,
                            "height": 8.602941176470589
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_aecJMLj.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "958861",
                        "field1": "SAMSUNG GALAXY S10",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG GALAXY S10",
                        "grammage": null,
                        "price": "699.00",
                        "price_label": "€ 699,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Black",
                            "White",
                            "Green"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 0.0,
                            "left": 34.39662990196078,
                            "width": 31.477022058823525,
                            "height": 31.02941176470588
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_fZwoici_fa1waaU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "978768",
                        "field1": "SAMSUNG GALAXY A30S",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG GALAXY A30S",
                        "grammage": null,
                        "price": "239.00",
                        "price_label": "€ 239,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "White",
                            "Green",
                            "Black"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 0.0,
                            "left": 65.8736519607843,
                            "width": 34.12634803921567,
                            "height": 31.02941176470588
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_MXimRjO_JCRlcxQ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "973891",
                        "field1": "XIAOMI NOTE 8 T",
                        "field2": "SMARTPHONE DUAL SIM",
                        "field3": "",
                        "field4": "",
                        "description": "XIAOMI NOTE 8 T SMARTPHONE DUAL SIM",
                        "grammage": null,
                        "price": "199.00",
                        "price_label": "€ 199,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Xiaomi",
                        "blueprint": {
                            "top": 30.220588235294116,
                            "left": 0.0,
                            "width": 34.12634803921567,
                            "height": 31.02941176470588
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_bqgA6Dm_HU2j9P6.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "921914",
                        "field1": "HUAWEI Y5 2019",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "HUAWEI Y5 2019",
                        "grammage": null,
                        "price": "99.90",
                        "price_label": "€ 99,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Huawei",
                        "blueprint": {
                            "top": 30.220588235294116,
                            "left": 33.849203431372544,
                            "width": 34.12634803921567,
                            "height": 31.02941176470588
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_lAxJvJl_LWYUJ6d.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "988924",
                        "field1": "NEFFOS C5PLUS",
                        "field2": "SMARTPHONE",
                        "field3": "GREY/16GB",
                        "field4": "",
                        "description": "NEFFOS C5PLUS SMARTPHONE GREY/16GB",
                        "grammage": null,
                        "price": "49.90",
                        "price_label": "€ 49,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Neffos",
                        "blueprint": {
                            "top": 30.294117647058822,
                            "left": 66.23860294117647,
                            "width": 33.76139705882351,
                            "height": 31.02941176470588
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_m87Aude_VR7fFHz.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "959530",
                        "field1": "NOKIA 2.2",
                        "field2": "DUAL SIM",
                        "field3": "16 GB",
                        "field4": "",
                        "description": "NOKIA 2.2 DUAL SIM 16 GB",
                        "grammage": null,
                        "price": "79.90",
                        "price_label": "€ 79,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Nokia",
                        "blueprint": {
                            "top": 60.73529411764705,
                            "left": 0.0,
                            "width": 33.76139705882351,
                            "height": 30.147058823529413
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_uAtkiSy_9KKlldV.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "959530",
                        "field1": "BRONDI AMICO FLIP+",
                        "field2": "DUAL SIM",
                        "field3": "",
                        "field4": "",
                        "description": "BRONDI AMICO FLIP+ DUAL SIM",
                        "grammage": null,
                        "price": "49.90",
                        "price_label": "€ 49,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Brondi",
                        "blueprint": {
                            "top": 60.66176470588234,
                            "left": 33.48425245098039,
                            "width": 31.389215686274493,
                            "height": 30.147058823529413
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_ommnzzV_hzPM3d7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "957833",
                        "field1": "SBS AURICOLARI TWIN HOP",
                        "field2": "WIRELESS",
                        "field3": "",
                        "field4": "",
                        "description": "SBS AURICOLARI TWIN HOP WIRELESS",
                        "grammage": null,
                        "price": "29.90",
                        "price_label": "€ 29,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Audio",
                        "subcategory": "Cuffie",
                        "blueprint": {
                            "top": 60.66176470588234,
                            "left": 64.6875612745098,
                            "width": 35.31243872549018,
                            "height": 30.147058823529413
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_ox85wBa_3pCJM73.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 15,
                "image_file": "/media/interactive_flyer_87/pages/page_15_cQwKQuG.jpg",
                "products": [
                    {
                        "item_id": "965298",
                        "field1": "AMAZFIT SMARTWATCH",
                        "field2": "1,28\" BIP LITE",
                        "field3": "",
                        "field4": "",
                        "description": "AMAZFIT SMARTWATCH 1,28\" BIP LITE",
                        "grammage": null,
                        "price": "33.90",
                        "price_label": "€ 33,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Smartwatch",
                        "blueprint": {
                            "top": 4.485294117647059,
                            "left": 1.3685661764705883,
                            "width": 31.277328431372545,
                            "height": 26.603770789712794
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_8TFE8pq_gH2jR6r.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "974829",
                        "field1": "AMAZFIT SMARTWATCH",
                        "field2": "CASSA IN ACCIAIO",
                        "field3": "GTR 42CM",
                        "field4": "",
                        "description": "AMAZFIT SMARTWATCH CASSA IN ACCIAIO GTR 42CM",
                        "grammage": null,
                        "price": "114.90",
                        "price_label": "€ 114,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Smartwatch",
                        "blueprint": {
                            "top": 8.01470588235294,
                            "left": 27.097610294117644,
                            "width": 39.39748774509803,
                            "height": 23.00082961324221
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_SKOuHwO_t3nB9v9.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "962907",
                        "field1": "AMAZFIT SMARTWATCH",
                        "field2": "STRATOS 2",
                        "field3": "",
                        "field4": "",
                        "description": "AMAZFIT SMARTWATCH STRATOS 2",
                        "grammage": null,
                        "price": "134.90",
                        "price_label": "€ 134,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Smartwatch",
                        "blueprint": {
                            "top": 6.617647058823529,
                            "left": 65.8736519607843,
                            "width": 31.91599264705882,
                            "height": 24.471417848536326
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_Vqqo8RY_MNq09zl.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "969486",
                        "field1": "SAMSUNG GALAXY WATCH",
                        "field2": "ACTIVE",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG GALAXY WATCH ACTIVE",
                        "grammage": null,
                        "price": "169.00",
                        "price_label": "€ 169,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Smartwatch",
                        "blueprint": {
                            "top": 34.411764705882355,
                            "left": 0.0,
                            "width": 34.1969362745098,
                            "height": 27.48612373088927
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_wY3Xx1z_lqtt9g9.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "946378",
                        "field1": "DORO TELEFONO",
                        "field2": "CELLULARE 1361",
                        "field3": "DUALSIM",
                        "field4": "",
                        "description": "DORO TELEFONO CELLULARE 1361 DUALSIM",
                        "grammage": null,
                        "price": "29.90",
                        "price_label": "€ 29,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Telefonia",
                        "subcategory": "Doro",
                        "blueprint": {
                            "top": 34.26470588235294,
                            "left": 33.48425245098039,
                            "width": 30.273713235294114,
                            "height": 26.603770789712794
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_Cn2Shpd_ooO6q6z.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "951199",
                        "field1": "NINTENDO DS2 XL",
                        "field2": "CONSOLE GIOCHI",
                        "field3": "",
                        "field4": "",
                        "description": "NINTENDO DS2 XL CONSOLE GIOCHI",
                        "grammage": null,
                        "price": "79.90",
                        "price_label": "€ 79,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Console",
                        "blueprint": {
                            "top": 33.75,
                            "left": 64.32261029411765,
                            "width": 35.586151960784306,
                            "height": 26.603770789712794
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_mN3bq3u_dvsxSyl.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "982846",
                        "field1": "VIVO HOVERBOARD",
                        "field2": "MVB80",
                        "field3": "",
                        "field4": "",
                        "description": "VIVO HOVERBOARD MVB80",
                        "grammage": null,
                        "price": "99.90",
                        "price_label": "€ 99,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Hoverboard",
                        "blueprint": {
                            "top": 67.27941176470587,
                            "left": 0.0,
                            "width": 30.568075980392152,
                            "height": 23.589064907359855
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_AZeLdLm_f382JDX.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "982218",
                        "field1": "ICONBIT HOVERBOARD",
                        "field2": "MEKOTRON",
                        "field3": "",
                        "field4": "",
                        "description": "ICONBIT HOVERBOARD MEKOTRON",
                        "grammage": null,
                        "price": "79.90",
                        "price_label": "€ 79,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Hoverboard",
                        "blueprint": {
                            "top": 67.27941176470587,
                            "left": 30.382169117647056,
                            "width": 32.48406862745098,
                            "height": 23.589064907359855
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_Un7zkgj_Z3eeNjQ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": "940449",
                        "field1": "NILOX BICI ELETTRICA",
                        "field2": "DOC E-BIKE X1",
                        "field3": "",
                        "field4": "",
                        "description": "NILOX BICI ELETTRICA DOC E-BIKE X1",
                        "grammage": null,
                        "price": "399.00",
                        "price_label": "€ 399,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Bici",
                        "blueprint": {
                            "top": 60.294117647058826,
                            "left": 62.862806372549024,
                            "width": 37.137193627450976,
                            "height": 30.574359025006913
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_87/products/cropped_image_OScsgCa_4FZQzvb.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 16,
                "image_file": "/media/interactive_flyer_87/pages/page_16_iHp57ED.jpg",
                "products": []
            },
            {
                "number": 17,
                "image_file": "/media/interactive_flyer_87/pages/fine_pagina_Trony.png",
                "products": []
            }
        ]
    }
    
    }   
}

const lefletSwitch = () => {
    return leaflets.trony;
}

export const serverResponse = lefletSwitch();


