import React, { Component, createRef } from 'react';
import Product from '../components/productsList/Product';
import { connect } from 'react-redux';
import {
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    CustomInput,
    Label,
    Row,
    Col,
    Alert,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faBars,
    faStream,
    faArrowCircleUp
} from '@fortawesome/free-solid-svg-icons';
import '../assets/css/custom.css';
import InfiniteScroll from 'react-infinite-scroller';

import { serverResponse } from '../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../settings/gaConfig';

import { isIE, isEdge, isMobile } from 'react-device-detect';

class Categories extends Component {



    state = {
        products: [],
        nameFilter: "",
        brandFilter: false,
        underpriceFilter: false,
        categoryFilter: "",
        subCategoryFilter: "",
        //For Infinite Scroll
        items: 10,
        hasMoreItems: true,
        showScrollUp: false,
    }

    handleChangeName = (e) => {
        this.setState({
            nameFilter: e.target.value,
            items: 10,
            hasMoreItems: true,
        });
        document.getElementById('scrollable-div').scrollTop = 0;
    }

    handleChangeBrand = (e) => {
        this.setState({
            brandFilter: e.target.checked,
            items: 10,
            hasMoreItems: true,
        });
        document.getElementById('scrollable-div').scrollTop = 0;
    }

    handleChangeUnderprice = (e) => {
        this.setState({
            underpriceFilter: e.target.checked,
            items: 10,
            hasMoreItems: true,
        });
        document.getElementById('scrollable-div').scrollTop = 0;
    }

    handleChangeCategory = (e) => {
        this.setState({
            categoryFilter: e.target.value,
            subCategoryFilter: "",
            items: 10,
            hasMoreItems: true,
        });
        document.getElementById('scrollable-div').scrollTop = 0;
    }

    handleChangeSubCategory = (e) => {
        this.setState({
            subCategoryFilter: e.target.value,
            items: 10,
            hasMoreItems: true,
        });
        document.getElementById('scrollable-div').scrollTop = 0;
    }

    productFilter = (product) => {

        let brandCheck = (this.state.brandFilter)
            ? (product.pam) ? true : false
            : true;
        let underpriceCheck = (this.state.underpriceFilter)
            ? (product.underpriced_product) ? true : false
            : true;
        let nameCheck = product.description.toLowerCase().includes(this.state.nameFilter.toLowerCase()) ? true : false;
        let categoryCheck = (this.state.categoryFilter !== "")
            ? product.category.toLowerCase() === this.state.categoryFilter.toLowerCase() ? true : false
            : true
        let subcategoryCheck = (this.state.subCategoryFilter !== "")
            ? product.subcategory.toLowerCase() === this.state.subCategoryFilter.toLowerCase() ? true : false
            : true

        return brandCheck && nameCheck && underpriceCheck && categoryCheck && subcategoryCheck;
    }

    resetFilters = () => {
        this.setState({
            nameFilter: "",
            brandFilter: false,
            underpriceFilter: false,
            categoryFilter: "",
            subCategoryFilter: "",
            items: 10,
            hasMoreItems: true,
        });
        document.getElementById('scrollable-div').scrollTop = 0;
    }

    fetchMoreData = () => {
        const {
            products,
            currentProducts,
            numProducts,
        } = this.state;
        if (currentProducts.length >= products.length) {
            this.setState({ hasMore: false });
            return;
        }

        var tmpProducts = products.filter(this.productFilter);
        this.setState({
            currentProducts: tmpProducts.slice(0, numProducts),
            numProducts: numProducts + 10,
        })
    }


    //Infinite Loop      
    showItems = () => {
        var currentProducts = this.state.products.filter(this.productFilter);
        currentProducts = currentProducts.slice(0, this.state.items);

        const items = currentProducts.map((element, key) => {
            return (
                <div key={key} className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <Product info={element} />
                </div>
            );
        })
        return items;
    }

    loadMore = () => {
        if (this.state.items > this.state.products.length) {
            this.setState({ hasMoreItems: false });
        } else {
            this.setState({ items: this.state.items + 10 });
        }
    }

    componentWillMount() {
        let tmpProducts = [];
        let tmp = Object.assign({}, serverResponse);

        tmp.leaflet.pages.forEach((page) => {
            tmpProducts = tmpProducts.concat(page.products);
        });

        tmpProducts.sort((a, b) => {
            const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
            if (aID === -1) return +1
            if (bID === -1) return -1
            return aID - bID
        });

        this.setState({
            products: tmpProducts,
        });
    }

    componentDidMount() {
        if (this.props.match.params.name !== undefined) {
            this.setState({ nameFilter: this.props.match.params.name });
        }

        if (serverResponse.config.ga_active) {
            ReactGA.initialize(serverResponse.config.ga_tracking_id);
            ReactGA.pageview("/" + serverResponse.config.release_id + "/categorie");
        }
    }

    render() {

        const {
            nameFilter,
            brandFilter,
            underpriceFilter,
            categoryFilter,
            subCategoryFilter,
            hasMoreItems,
            showScrollUp
        } = this.state;

        let subcategories = [];
        if (this.state.categoryFilter !== "") {
            serverResponse.leaflet.categories.map(element => {
                if (element.category_name === this.state.categoryFilter) {
                    subcategories = element.subcategories;
                }
                return false;
            })
        }
        /*<CustomInput type="checkbox" id="branded-products" label="Prodotti a marchio" checked={brandFilter} onChange={this.handleChangeBrand} />
                                        <CustomInput type="checkbox" id="underprice-products" label="Carta arancio" checked={underpriceFilter} onChange={this.handleChangeUnderprice} />
            
                                        */
        let brandInput = "";
        let underpriceInput = "";
        if (Boolean(serverResponse.config.filterBrand) === true) {
            brandInput = <CustomInput type="checkbox" id="branded-products" label="Prodotti a marchio" checked={brandFilter} onChange={this.handleChangeBrand} />
        }
        if (Boolean(serverResponse.config.filterUnderprice) === true) {
            underpriceInput = <CustomInput type="checkbox" id="underprice-products" label="Carta arancio" checked={underpriceFilter} onChange={this.handleChangeUnderprice} />
        }

        const scrollableClass = {
            height: "60vh",
            width: "100%",
            overflow: "auto"
        }

        return (
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15" style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                        <div className="roe-card-style">
                            <div className="roe-card-header">
                                {/* <h3>Filtri</h3> */}
                            </div>
                            <div className="roe-card-body">
                                <Row>
                                    <Col md="4" className="d-none d-sm-block">
                                        <Label for="product">Cosa cerchi?</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faSearch} /></span></InputGroupAddon>
                                            <Input placeholder="Prodotto" id="product" onChange={this.handleChangeName} value={nameFilter} />
                                        </InputGroup>
                                    </Col>

                                    <Col md="4">
                                        <Label for="category">Seleziona categoria</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faBars} /></span></InputGroupAddon>
                                            <Input type="select" name="select" id="category" value={categoryFilter} onChange={this.handleChangeCategory}>
                                                <option value="">Scegli una categoria</option>

                                                {
                                                    /*serverResponse.leaflet.category.map((element, key) => {
                                                        return <option key={key} value={element}>{element}</option>
                                                    })*/
                                                    serverResponse.leaflet.categories.map((element, key) => {
                                                        return <option key={key} value={element.category_name}>{element.category_name}</option>
                                                    })
                                                }
                                            </Input>
                                        </InputGroup>
                                    </Col>

                                    <Col md="4">
                                        <Label for="subcategory">Seleziona sottocategoria</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faStream} /></span></InputGroupAddon>
                                            <Input type="select" name="select" id="subcategory" value={subCategoryFilter} onChange={this.handleChangeSubCategory}>
                                                <option value="">Scegli una sottocategoria</option>
                                                {/*<option>Acqua</option>
                                                <option>Succhi e bibite</option>
                                                <option>Vini e Birre</option>*/}
                                                {
                                                    subcategories.map((element, key) => {
                                                        return <option key={key} value={element}>{element}</option>
                                                    })
                                                }
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "20px" }}>
                                    <Col md="4">
                                        {/*<CustomInput type="checkbox" id="branded-products" label="Prodotti a marchio" checked={brandFilter} onChange={this.handleChangeBrand} />
                                        <CustomInput type="checkbox" id="underprice-products" label="Carta arancio" checked={underpriceFilter} onChange={this.handleChangeUnderprice} />
                                            */}
                                        {brandInput}
                                        {underpriceInput}
                                    </Col>
                                    <Col md="4">
                                    </Col>
                                    <Col md="4" style={{ textAlign: "right" }}>
                                        <Button color="primary" onClick={() => this.resetFilters()}>Reset</Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="scrollable-div" style={isMobile ? {} : scrollableClass}
                    onScroll={(e) => {
                        if (e.target.scrollTop > 300) {
                            if (!this.state.showScrollUp) {
                                this.setState({
                                    showScrollUp: true
                                })
                            }
                        } else {
                            if (this.state.showScrollUp) {
                                this.setState({
                                    showScrollUp: false
                                })
                            }
                        }
                    }}
                >
                    <InfiniteScroll
                        id="scroller"
                        loadMore={this.loadMore}
                        hasMore={hasMoreItems}
                        loader={
                            <div key="loader" className="col-sm-12 col-md-12 col-lg-12 ptb-15">
                                <Alert className="c-light">Loading...</Alert>
                            </div>
                        }
                        useWindow={false}
                        className="row ma-0 filtered-products"
                    >

                        {this.showItems()}

                    </InfiniteScroll>
                </div>
                <FontAwesomeIcon
                    size="3x"
                    className="scroll-up-btn"
                    style={showScrollUp ? { display: "block" } : { display: "none" }}
                    icon={faArrowCircleUp}
                    onClick={() => {
                        if (isIE || isEdge) {
                            document.getElementById('scrollable-div').scrollTop = 0;
                        } else {
                            document.getElementById('scrollable-div').scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.themeChanger
    };
}

export default connect(mapStateToProps, null)(Categories);