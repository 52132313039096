import React, { Component } from 'react';
import Carousel from 'nuka-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from "reactstrap";
import {
    faAngleLeft,
    faAngleRight
} from '@fortawesome/free-solid-svg-icons';

import CustomSlide from './CustomSlide';
import CustomIndex from './CustomIndex';
import {
    isMobile,
    isIE,
    isIOS
} from "react-device-detect";
import '../../assets/css/custom.css';
import axios from 'axios';
// import { serverResponse } from '../../../util/fakeServer';
import { serverResponse } from '../../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../../settings/gaConfig';

import Modal from 'react-modal';
import ReactPlayer from 'react-player'

let videoModalStyles = {
    content: {
        background: "transparent",
        border: 0,
    },
    overlay: {
        zIndex: 999999999
    }
};

if (isMobile) {
    videoModalStyles = {
        content: {
            background: "transparent",
            border: 0,
            padding: 0,
            top: "10px",
            left: "10px",
            bottom: "10px",
            right: "10px",
        },
        overlay: {
            zIndex: 999999999
        }
    };
}

class CustomCarousel extends Component {

    // state = {
    //     slideIndex: 0,
    //     swipe: true,
    //     video_url: "",
    //     video_playing: false,
    //     video_modal_open: false
    // }

    constructor(props) {
        super(props);

        let leafleat_has_video = false;
        let pages_with_video = null;
        let video_only_once = false;
        let video_mandatory_seconds = 0;

        if (serverResponse.hasOwnProperty('video')) {
            leafleat_has_video = true;
            pages_with_video = serverResponse.video.videos.map(i => i.page);
            video_only_once = serverResponse.video.show_only_once;
            video_mandatory_seconds = serverResponse.video.mandatory_seconds;
        }

        this.state = {
            loaded: false,
            slideIndex: 0,
            swipe: true,
            video_url: "",
            video_muted: true,
            video_playing: false,
            video_modal_open: false,
            leafleat_has_video: leafleat_has_video,
            pages_with_video: pages_with_video,
            show_close_video: false,
            preroll_video_in_progress: false,
            video_only_once: video_only_once,
            video_page_number: null,
            video_page_item_id: null,
            countdown: video_mandatory_seconds,
            video_mandatory_seconds: video_mandatory_seconds
        }

        this.closeVideoModal = this.closeVideoModal.bind(this);
        this.onVideoProgress = this.onVideoProgress.bind(this);
        this.onVideoStart = this.onVideoStart.bind(this);
        this.toggleVideoMuted = this.toggleVideoMuted.bind(this);
        this.showPageVideo = this.showPageVideo.bind(this);
        this.onVideoEnded = this.onVideoEnded.bind(this);
        this.userCloseVideo = this.userCloseVideo.bind(this);
    }

    ref = player => {
        this.player = player
    }

    toggleVideoMuted() {
        this.setState({ video_muted: !this.state.video_muted })
    }

    onVideoEnded() {
        this.closeVideoModal();
        if (serverResponse.config.ga_active) {
            ReactGA.event({
                category: serverResponse.config.release_id,
                action: gaEventLabel.page_video_completion,
                label: String(this.state.video_page_item_id)
            });
        }
    }

    userCloseVideo() {
        this.closeVideoModal();
        if (serverResponse.config.ga_active) {
            ReactGA.event({
                category: serverResponse.config.release_id,
                action: gaEventLabel.page_video_closed_by_user,
                label: String(this.state.video_page_item_id)
            });
        }
    }

    closeVideoModal() {
        this.setState({
            video_modal_open: false,
            video_url: "",
            preroll_video_in_progress: false,
            show_close_video: false,
        });
    }

    showPageVideo(page_number, onload = false) {
        if (this.state.video_only_once) {
            let session_video_pages = sessionStorage.getItem('video_pages');
            if (session_video_pages == null) {
                sessionStorage.setItem('video_pages', JSON.stringify([page_number]));
            } else {
                let session_video_pages_parsed = JSON.parse(session_video_pages);
                if (session_video_pages_parsed.includes(page_number)) {
                    return
                } else {
                    session_video_pages_parsed.push(page_number);
                    sessionStorage.setItem('video_pages', JSON.stringify(session_video_pages_parsed));
                }
            }
        }

        this.setState({
            video_modal_open: true,
            video_url: process.env.PUBLIC_URL + serverResponse.video.videos.filter(i => i.page == page_number)[0].url,
            video_playing: true,
            video_muted: (onload ? true : isIOS),
            video_page_number: page_number,
            video_page_item_id: serverResponse.video.videos.filter(i => i.page == page_number)[0].item_id,
            countdown: this.state.video_mandatory_seconds
        },
        () => {
            if (serverResponse.config.ga_active) {
                ReactGA.event({
                    category: serverResponse.config.release_id,
                    action: gaEventLabel.page_video_play,
                    label: String(this.state.video_page_item_id)
                });
            }
        });
    }

    onVideoProgress(args) {
        // { played: 0.12, playedSeconds: 11.3, loaded: 0.34, loadedSeconds: 16.7 }
        if (this.state.preroll_video_in_progress) {
            this.setState({
                // countdown: parseInt(this.state.countdown - parseInt(args.playedSeconds))
                countdown: parseInt(this.state.countdown - 1)
            });
            if (args.playedSeconds > this.state.video_mandatory_seconds) {
                this.setState({
                    preroll_video_in_progress: false,
                    show_close_video: true
                });
                if (serverResponse.config.ga_active) {
                    ReactGA.event({
                        category: serverResponse.config.release_id,
                        action: gaEventLabel.page_video_mandatory_completed,
                        label: String(this.state.video_page_item_id)
                    });
                }
            }
        }
    }

    onVideoStart() {
        // console.log("video start", Date.now());
        this.setState({
            preroll_video_in_progress: true
        });
    }

    goToPage = (index) => {
        //TODO
        //axios per l'incremento del counter di visualizzazioni della pagina index
        /*axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {

            }).catch(error => {
                //console.log(error);
            });*/

        this.setState({
            slideIndex: index
        }, (() => {
            // this.forceUpdate();
            this.setState(this.state);
        }));
    }

    disableSwipe = () => this.setState({ swipe: !this.state.swipe });
    setSwipe = (swipe) => {
        this.setState({ swipe: swipe });
        if (isIE) {
            // IE + iFrame: la modal si apre dietro l'elemento 'scrollbars'
            // forzo lo z-index
            document.getElementById('scrollbars').style['z-index'] = swipe ? "" : '-1';
        }
    };
    isSwipeDisabed = () => {
        return this.state.swipe ? false : true;
    };

    handleChangeOrientation = () => {
        this.setState({ state: this.state });
    }

    componentDidMount() {

        window.addEventListener('orientationchange', this.handleChangeOrientation);
        let pageIndex = this.props.setPageIndex;
        if (pageIndex !== undefined) {
            pageIndex = parseInt(pageIndex);
            if (isMobile || pageIndex % 2 !== 0) {
                pageIndex--;
            }
            this.goToPage(pageIndex);
        }
        else {
            if(this.state.leafleat_has_video){
                if (this.state.pages_with_video.includes(1)) {
                    this.showPageVideo(1, true);
                } else if (this.state.pages_with_video.includes(this.state.slideIndex)){
                    this.showPageVideo(this.state.slideIndex, true);
                }
            }
        }
        setTimeout(() => window.dispatchEvent(new Event("resize")), 0);

        if (serverResponse.config.ga_active) {
            ReactGA.initialize(serverResponse.config.ga_tracking_id);
            if (!isMobile) {
                ReactGA.event({
                    category: serverResponse.config.release_id,
                    action: gaEventLabel.open_page,
                    label: String(0)
                });
            }
            ReactGA.event({
                category: serverResponse.config.release_id,
                action: gaEventLabel.open_page,
                label: String(1)
            });
        }

        this.setState({
            loaded: true
        });
    }

    render() {

        const {
            slideIndex,
            swipe,
        } = this.state;

        //Slides
        const listSlides = this.props.leaflet.pages.map((element, key) =>
            <CustomSlide pageNumber={element.number} key={key} imagePath={element.image_file} products={element.products} leafletSwipe={this.disableSwipe} isSwipeDisabed={this.isSwipeDisabed} setSwipe={this.setSwipe} />
        )

        //Pagination
        const listPages = [];
        listPages.push(<Button className="carousel-pagination-button" key={0} color="primary" onClick={() => this.setState({ slideIndex: 0 })} active={this.state.slideIndex === 0} >1</Button>);
        for (var i = 1; i < this.props.leaflet.pages.length - 2; i = i + 2) {
            let index = i;
            (this.props.leaflet.pages.length === index + 1)
                ? listPages.push(<Button className="carousel-pagination-button" key={index} color="primary" onClick={() => this.setState({ slideIndex: index + 1 })} active={this.state.slideIndex === index + 1} > {i + 1} </Button>)
                : listPages.push(<Button className="carousel-pagination-button" key={index} color="primary" onClick={() => this.setState({ slideIndex: index + 1 })} active={this.state.slideIndex === index + 1} > {i + 1}-{i + 2} </Button>)
        }
        if (this.props.leaflet.pages.length % 2 !== 0) {
            listPages.push(<Button className="carousel-pagination-button" key={this.props.leaflet.pages.length - 1} color="primary" onClick={() => this.setState({ slideIndex: this.props.leaflet.pages.length - 1 })} active={this.state.slideIndex === this.props.leaflet.pages.length - 1} > {this.props.leaflet.pages.length - 1} </Button>);
        }
        // listPages.push(<Button className="carousel-pagination-button" key={this.props.leaflet.pages.length - 1} color="primary" onClick={() => this.setState({ slideIndex: this.props.leaflet.pages.length - 1 })} active={this.state.slideIndex === this.props.leaflet.pages.length - 1} > {this.props.leaflet.pages.length - 1} </Button>);

        var blankDiv;
        if (this.props.leaflet.pages.length % 2 === 0) {
            blankDiv = <div></div>;
        }

        return (
            <div>
                <Carousel
                    heightMode='max'
                    ref="carouselRef"
                    slideIndex={slideIndex}
                    slidesToShow={isMobile ? 1 : 2}
                    slidesToScroll={isMobile ? 1 : 2}
                    cellAlign={isMobile ? "center" : "left"}
                    dragging={swipe}
                    swiping={swipe}
                    speed={500}
                    renderBottomCenterControls={null}
                    renderCenterLeftControls={({ previousSlide }) => {
                        if (isMobile) return null; else return <FontAwesomeIcon className="carousel-arrow-left" icon={faAngleLeft} size="3x" onClick={previousSlide} />;
                    }}
                    renderCenterRightControls={({ nextSlide }) => {
                        if (isMobile) return null; else return <FontAwesomeIcon className="carousel-arrow-right" icon={faAngleRight} size="3x" onClick={nextSlide} />
                    }}
                    beforeSlide={
                        (slideIndex) => {

                        }}

                    afterSlide={
                        (slideIndex) => {
                            this.goToPage(slideIndex);

                            if (this.state.leafleat_has_video) {
                                let target_page = slideIndex;
                                let target_page_related = target_page;
                                if (!isMobile) {
                                    if (target_page % 2 == 1) {
                                        if (serverResponse.leaflet.pages.length < target_page) {
                                            target_page_related = target_page - 1;
                                        }
                                    } else {
                                        target_page_related = target_page + 1;
                                    }
                                } else {
                                    target_page = slideIndex + 1;
                                    target_page_related = target_page;
                                }

                                if (this.state.pages_with_video.includes(target_page)) {
                                    this.showPageVideo(target_page);
                                } else if (this.state.pages_with_video.includes(target_page_related)) {
                                    this.showPageVideo(target_page_related);
                                }
                            }

                            if (serverResponse.config.ga_active) {
                                ReactGA.event({
                                    category: serverResponse.config.release_id,
                                    action: gaEventLabel.open_page,
                                    label: String((isMobile) ? (slideIndex + 1) : slideIndex)
                                });
                                if ((!isMobile) && (serverResponse.leaflet.pages.length >= (slideIndex + 1))) {
                                    ReactGA.event({
                                        category: serverResponse.config.release_id,
                                        action: gaEventLabel.open_page,
                                        label: String(slideIndex + 1)
                                    });
                                }
                            }
                        }}>

                    {!isMobile && <CustomIndex index={this.props.leaflet.index} goToPage={this.goToPage} />}
                    {listSlides}
                    {!isMobile && blankDiv}

                </Carousel>


                {
                    !isMobile &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                        <ButtonGroup>
                            {listPages}
                        </ButtonGroup>
                    </div>
                }

                <Modal
                    isOpen={this.state.video_modal_open}
                    style={videoModalStyles}
                    ariaHideApp={false}
                >
                    <div className="modal-page-video-container">
                        {
                            this.state.video_muted &&
                            <button onClick={this.toggleVideoMuted} className="audio-page-video-button">
                                <i className="fas fa-volume-mute" />
                                <span style={{ paddingLeft: "10px" }}>Attiva audio</span>
                            </button>
                        }
                        {
                            this.state.show_close_video &&
                            <button onClick={this.userCloseVideo} className="close-page-video-button">
                                <i className="fas fa-times" />
                            </button>
                        }
                        {
                            (! this.state.show_close_video) && (this.state.countdown > 0) && 
                            <button className="wait-page-video-button">
                                Sfoglia il volantino tra {this.state.countdown} secondi
                            </button>
                        }
                        
                        
                        <ReactPlayer
                            className='react-page-player'
                            url={this.state.video_url}
                            playing={this.state.video_playing}
                            width='100%'
                            height='100%'
                            onProgress={this.onVideoProgress}
                            onStart={this.onVideoStart}
                            onEnded={this.onVideoEnded}
                            muted={this.state.video_muted}
                            playsinline={isMobile}
                            ref={this.ref}
                            volume={1}
                        />
                    </div>

                </Modal>
            </div>
        );
    }
}

export default CustomCarousel;