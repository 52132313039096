import React, { Component } from 'react';
import CustomProduct from './CustomProduct';
import { isMobile } from "react-device-detect";

export default class CustomSlide extends Component {

    render() {

        const containerClass = {
            position: "relative",
            display: "inline-block",
            // width: "100%",
        }

        const imgClass = {
            maxHeight: "calc(100vh - 170px)",
            // minHeight: "500px",
            maxWidth: "100%"
        }

        const imgMobileClass = {
            width: "100%"
        }

        const wrapperClass = {
            width: "100%",
            textAlign: ((this.props.pageNumber % 2 == 0) ? "right" : "left"),
        }

        const listProducts = this.props.products.map((element, key) =>
            <CustomProduct key={key} info={element} leafletSwipe={this.props.leafletSwipe} isSwipeDisabed={this.props.isSwipeDisabed} setSwipe={this.props.setSwipe} />
        )

        return (
            <div style={wrapperClass}>
                <div style={containerClass}>
                    <img src={process.env.PUBLIC_URL + this.props.imagePath} style={isMobile ? imgMobileClass : imgClass} alt="" />

                    {listProducts}

                </div>
            </div>
        );
    }
}